import React from 'react';

import { withRegion } from 'helpers/withRegion';
import Footer from './Footer';

import 'styles/components/Footer.scss';

class FooterWrapper extends React.Component {
  getCompanyLegalName() {
    switch (this.props.region) {
      case 'nz':
        return 'Art Money New Zealand Limited NZBN 9429042003243';
      case 'us':
        return 'Art Money U.S., Inc.';
      case 'uk':
        return 'Art Money UK Ltd';
      default:
        return 'Art Money Pty Ltd ABN 65 168 905 388, Australian Credit Licence 470221';
    }
  }

  render() {
    return (
      <footer className="footer">
        <Footer noRouter={this.props.noRouter} />
        <div className="copyright">
          <p>{this.getCompanyLegalName()}</p>
          <p className="copyright__divider">|</p>
          <p>Copyright {new Date().getFullYear()} Art Money &reg;</p>
          <p className="copyright__divider">|</p>
          <p>Website by Custom D</p>
        </div>
      </footer>
    );
  }
}

export default withRegion(FooterWrapper);
