import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import VerifiedBuyerStatus from './VerifiedBuyerStatus';
import VerifiedBuyerMessage from './VerifiedBuyerMessage';

const VerifiedBuyerDashboard = ({ status }) => {
  return (
    <motion.div
      className="section client-dashboard-inner__wrap"
      animate={1 ? { opacity: [0, 1] } : fadeInDown}
      transition={{ duration: 0.5, ease: 'easeOut', delay: 0 }}
    >
      <VerifiedBuyerMessage status={status} />
      {status?.vba_status && <VerifiedBuyerStatus status={status} />}
    </motion.div>
  );
};

export default VerifiedBuyerDashboard;
