import React from 'react';
import {Modal} from '@material-ui/core';
import CloseIcon from '../../Icons/CloseIcon';
import PropTypes from 'prop-types';
import SharedCopyForReasoningModals from './SharedCopyForReasoningModals';

function DeclineReasoning({isOpen, handleCloseModal, locAlloyApplicationData}) {
  const outcomeReasons = locAlloyApplicationData.outcome_reasons ? locAlloyApplicationData.outcome_reasons : [];

  return (
    <Modal className="status-modal" open={isOpen} onClose={handleCloseModal} aria-labelledby="modal-title">
      <div className="row">
        <div className="status-modal__bg">
          <button type="button" className="status-modal__close float-right" onClick={handleCloseModal} aria-label="close popup">
            <CloseIcon />
          </button>
          <h2 id="modal-title">Credit application not approved</h2>
          <p>
            Thank you for your recent application for a line of credit through Art Money from our lending partner: Hatch Bank. Your request for a line of credit
            was carefully considered by our lending partner, and we regret that they are unable to approve your application at this time, for the following
            reason{outcomeReasons.length > 1 && 's'}:
          </p>
          <ul>
            {outcomeReasons.map((reason, index) => (
              <li key={index}>{reason.name}</li>
            ))}
          </ul>
          <SharedCopyForReasoningModals locAlloyApplicationData={locAlloyApplicationData} />
        </div>
      </div>
    </Modal>
  );
}

DeclineReasoning.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  locAlloyApplicationData: PropTypes.object.isRequired,
};

export default DeclineReasoning;
