/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress } from '@material-ui/core';

export default function LoadingButton({ loading, children, spinnerProps, containerProps, ...rest }) {
  return (
    <Button variant="contained" color="primary" disabled={loading} {...rest}>
      <Box visibility={loading ? 'hidden' : undefined} {...containerProps}>
        {children}
      </Box>
      <Box display="flex" position="absolute">{loading && <CircularProgress {...spinnerProps} />}</Box>
    </Button>
  );
}

LoadingButton.defaultProps = {
  loading: false,
  children: null,
  spinnerProps: {
    size: 22,
    color: 'secondary',
  },
  containerProps: {
    px: 4,
    py: 1.5,
  },
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  spinnerProps: PropTypes.shape({
    size: PropTypes.number,
    color: PropTypes.string,
  }),
  containerProps: PropTypes.shape({
    px: PropTypes.number,
    py: PropTypes.number,
  }),
};
