import React from 'react';

const verifiedBuyerStrings = (status) => {
  let message;
  switch (status) {
    case 'verified':
      message = (
        <div>
          <h3>Congratulations! You’re an Art Money Verified Buyer!</h3>
          <p>
            As a Verified Buyer you can use this status for every art purchase, instead of going through a separate process for each gallery or auction house —
            get verified once, use everywhere. Your personal information remains confidential with Art Money whilst the seller is able to meet their compliance
            requirement. We only share your status (verified or not), never your personal details, and will always ask for your permission first.
          </p>
        </div>
      );
      break;

    case 'pending':
      message = (
        <div>
          <h3>Your Verified Buyer application is under way</h3>
          <p>
            In many cases this will take only a few minutes, however should additional information be required we will be in touch, usually the next business
            day. Should you have any questions please contact <a href="mailto:accounts@artmoney.co.uk">accounts@artmoney.co.uk</a>
          </p>
        </div>
      );
      break;
    case 'expired':
      // Expiry/renewal is not yet implemented, but will be before the first AMVB clients' status expires (i.e. within one year).
      // Clients should not see this placeholder message before then.
      message = (
        <div>
          <h3>Your Verified Buyer status has expired.</h3>
          <p>Please contact Art Money to arrange renewal.</p>
        </div>
      );
      break;
    case 'non_vb':
      message = (
        <div>
          <h3>Become a Verified Buyer today!</h3>
          <p />
        </div>
      );
      break;
    default:
      message = '';
  }

  return {
    message,
  };
};

const Message = ({ status }) => {
  return (
    <div>
      <div>{status?.vba_status === 'verified' && verifiedBuyerStrings('verified').message}</div>
      <div>{status?.vba_status === 'pending' && verifiedBuyerStrings('pending').message}</div>
      <div>{status?.vba_status === 'not verified' && verifiedBuyerStrings('pending').message}</div>
      <div>{status?.vba_status === null && verifiedBuyerStrings('non_vb').message}</div>
    </div>
  );
};

export default Message;
