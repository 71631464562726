/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCamera } from 'react-icons/fi';
import { BsCameraVideo } from 'react-icons/bs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { Button } from '@material-ui/core';

import Uploader from './Uploader';
import TakePicture from './TakePicture';
import OnFido from './Onfido';
import contactStrings from '../contactStrings';

import 'styles/components/ClientDashboard/ClientDashboard.scss';

const uninterceptedAxiosInstance = axios.create({ baseURL: `${process.env.API_URL}`, withCredentials: true });

const VerificationPanel = ({ lineOfCredit, handleCompleteUpload, region }) => {
  const CountryCode = useMemo(() => {
    if (!lineOfCredit) {
      return region;
    }
    return lineOfCredit.country_code;
  }, [lineOfCredit]);

  const { number, email } = contactStrings(CountryCode);
  const [hasCamera, setHasCamera] = useState(false);
  const [takeImage, setTakeImage] = useState(null);
  const [images, setImages] = useState({});
  const [errors, setErrors] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (window.navigator.appName === 'Explorer') {
      setHasCamera(true);
    } else {
      navigator.mediaDevices.enumerateDevices().then(response => {
        const found = response.some(media => media.kind === 'videoinput');
        if (found) {
          setHasCamera(true);
        }
      });
    }
  }, []);

  const handleCloseImage = useCallback(
    base64 => {
      if (base64) {
        setImages(prev => ({ ...prev, [takeImage]: base64 }));
      }
      setTakeImage(null);
    },
    [takeImage],
  );

  const handleGotImage = useCallback((type, image) => {
    setImages(prev => ({ ...prev, [type]: image }));
  });

  const handleSubmit = useCallback(async () => {
    setSaving(true);
    setErrors(false);
    try {
      const { data: selfieRes } = await uninterceptedAxiosInstance.post('api/file/upload', { file: images.selfie });
      const { data: closeUpRes } = await uninterceptedAxiosInstance.post('api/file/upload', { file: images.close_up });
      if (!selfieRes?.data?.id || !closeUpRes?.data?.id) {
        throw new Error();
      } else {
        await uninterceptedAxiosInstance.post('api/idv/fv_images_submit', {
          fv_uploaded_selfie_id: selfieRes.data.id,
          fv_uploaded_document_id: closeUpRes.data.id,
        });
        handleCompleteUpload();
      }
    } catch (e) {
      let text = Object.values(e?.response?.data?.errors || {}).join(', ');
      if (text.length === 0) {
        text = 'Sorry, there was a problem uploading one of your images. Please try again or contact Art Money.';
      }
      setErrors(text);
    } finally {
      setSaving(false);
    }
  }, [images]);

  return (
    <div className="section">
      <div className="verification-panel">
        <div className="verification-panel__header">PLEASE BECOME A VERIFIED BUYER NOW</div>
        <div className="verification-panel__main">
          <div className="verification-panel__heading">
            Before finalising your art purchase, we’ll first need to verify your identity. This is a one-off security step to protect you and give you{' '}
            <i>Art Money Verified Buyer</i> status for future purchases.
          </div>
          <p className="verification-panel__text">
            To ensure it’s really you and to protect your identity, we use secure Photo ID verification. We’ll need to verify your identity using your photo ID
            the same doc you used in your application, usually driver licence or passport). This is a one-off process which for your convenience can be done in
            one of three ways.
          </p>
          <Tabs className="tab">
            <TabList>
              <Tab>
                <FiCamera />
                Verify Now
              </Tab>
              <Tab>
                <FiCamera />
                Upload Selfie
              </Tab>
              <Tab>
                <BsCameraVideo />
                Schedule Video Call
              </Tab>
            </TabList>

            <TabPanel>
              <OnFido handleCompleteUpload={handleCompleteUpload} />
            </TabPanel>
            <TabPanel>
              <p>
                To securely verify your identity, please upload a selfie with you holding a photo ID (driver&apos;s licence or passport) as well as a separate
                close-up of that same photo ID.
              </p>
              <div className="verification-panel__list">
                <p>
                  <strong>Please ensure that:</strong>
                </p>
                <ul>
                  <li>The photo is taken straight on</li>
                  <li>Your ID is held next to your face or under your chin</li>
                  <li>Your face and ID are clearly visible with no obstructions</li>
                </ul>
              </div>
              <div className="verification-panel__row ">
                <div className="verification-panel__block selfie account">
                  Selfie with ID*
                  <div className="selfie__block">
                    <Uploader handleGotImage={file => handleGotImage('selfie', file)} />
                    {images && images.selfie && (
                      <div className="selfie__image">
                        <img src={images.selfie} alt="wrong" style={{ height: '100%' }} />
                      </div>
                    )}
                  </div>
                  {hasCamera && takeImage === null && !saving && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setTakeImage('selfie')}
                      className="client-dashboard__button client-dashboard__button--ghost client-dashboard__button--round"
                    >
                      Take a picture
                    </Button>
                  )}
                </div>

                <div className="verification-panel__block close-up account">
                  Close-up of ID*
                  <div className="selfie__block">
                    <Uploader handleGotImage={file => handleGotImage('close_up', file)} />
                    {images && images.close_up && (
                      <div className="selfie__image">
                        <img src={images.close_up} alt="wrong" style={{ height: '100%' }} />
                      </div>
                    )}
                  </div>
                  {hasCamera && takeImage === null && !saving && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setTakeImage('close_up')}
                      className="client-dashboard__button client-dashboard__button--ghost client-dashboard__button--round"
                    >
                      Take a picture
                    </Button>
                  )}
                </div>
              </div>
              {takeImage && <TakePicture picture={takeImage} handleCloseImage={handleCloseImage} />}
              <div className="verification-panel__button-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  className="client-dashboard__button client-dashboard__button--ghost client-dashboard__button--round"
                  disabled={!images.selfie || !images.close_up || saving}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </div>
              {errors && <div className="verification-panel__error-wrap">{errors}</div>}
            </TabPanel>
            <TabPanel>
              <p className="verification-panel__text">
                You may choose to securely verify your identity over a video call (which will not be recorded), such as Zoom or Facetime. We’ll ask you to show
                us a photo ID (driver&apos;s licence or passport). Please <a href={`mailto: ${email}`}>contact us by email</a> or call{' '}
                <a href={`tel:${number}`}>{number}</a> to set up a mutually convenient call for your time zone.
              </p>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

VerificationPanel.defaultProps = {
  lineOfCredit: {},
};

VerificationPanel.propTypes = {
  lineOfCredit: PropTypes.shape({
    country_code: PropTypes.string,
  }),
  handleCompleteUpload: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
};

export default VerificationPanel;
