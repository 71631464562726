const contactStrings = CountryCode => {
  let number;
  let email;
  switch (CountryCode.toLowerCase()) {
    case 'nz':
      number = '0800 004 682';
      email = 'info@artmoney.co.nz';
      break;
    case 'au':
      number = '1300 31 66 11';
      email = 'info@artmoney.com.au';
      break;
    case 'gb':
    case 'uk':
      number = '+44 800 368 5468';
      email = 'info@artmoney.com';
      break;
    case 'us':
    default:
      number = '+1.888.886.8725';
      email = 'info@artmoney.com';
      break;
  }

  return {
    number,
    email,
  };
};

export default contactStrings;
