import React from 'react';
import PropTypes from 'prop-types';

const ReadyToArtMoney = () => {
  return (
    <>
      {/* Disabled while purchasing is shut down. */}
      {/* <h2>Ready to Art Money it?</h2>
      <p>Make a new Art Money purchase.</p> */}
    </>
  );
};

ReadyToArtMoney.defaultProps = {};

ReadyToArtMoney.propTypes = {};

export default ReadyToArtMoney;
