import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import OnFidoModal from '../OnFidoModal';

const OneMomentWhileWeReview = ({ clientEmail, isLoggedIn, isVerified, isUnderReview, config, changeTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLocallyVerified, setIsLocallyVerified] = useState(isVerified);

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOnFidoComplete = () => {
    setIsLocallyVerified(true);
    setIsOpen(false);
  };

  return (
    <>
      {isUnderReview && (
        <>
          <h2>Almost done!</h2>
          <p>We’re reviewing your application.</p>
          <p>This can be for a number of reasons, we’ll be in touch soon, generally next business day.</p>
        </>
      )}
      {(!isLoggedIn || !isVerified) && <h3>Whilst you're waiting...</h3>}
      <div className="status-two-columns">
        {!isLoggedIn && (
          <div className="status-column">
            <h4>Activate your Art Money Account</h4>
            <p>We've sent you an email at {clientEmail} with a link to set up your password and Art Money account.</p>
            <p>
              Please activate this as part of the verification process. (If you don’t receive that email in the next 5 minutes please check your junk folder).
            </p>
          </div>
        )}
        {!isLocallyVerified && (
          <div className="status-column">
            <h4>Verify your identity</h4>
            <p>The next step is to verify your identity, using a photo ID and your phone or webcam.</p>
            <p>It’s important we know it’s really you, and matching your face to your ID is how we do this.</p>
            <p>You will only be required to do this once and your data will remain private and securely held by Art Money.</p>
            {isLoggedIn ? (
              <p>
                <button onClick={() => changeTab('profile')} type="button" className="btn--pri dark text--center">
                  Verify your ID
                </button>
              </p>
            ) : (
              <>
                <p>
                  <button onClick={handleOpenModal} type="button" className="btn--pri dark text--center">
                    Verify your ID
                  </button>
                </p>
                <OnFidoModal isOpen={isOpen} handleCloseModal={handleCloseModal} handleOnFidoComplete={handleOnFidoComplete} config={config} />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

OneMomentWhileWeReview.defaultProps = {
  clientEmail: '',
  isLoggedIn: true,
  isVerified: false,
  isUnderReview: true,
  config: {},
  changeTab: () => {},
};

OneMomentWhileWeReview.propTypes = {
  clientEmail: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isVerified: PropTypes.bool,
  isUnderReview: PropTypes.bool,
  config: PropTypes.object,
  changeTab: PropTypes.func,
};

export default OneMomentWhileWeReview;
