import React, { useState, useEffect } from 'react';

import AxiosService from '../../../api/AxiosService';

const AgreementDownloadLink = ({ locId }) => {
  const [pdfLink, setPdfLink] = useState(false);

  const downloadUSCreditAgreementPdf = async () => {
    let pdf = '';
    try {
      pdf = await AxiosService.get('/lines_of_credit/' + locId + '/us_credit_agreement_pdf_download_link', undefined, {});
      setPdfLink(pdf?.data?.url);
    } catch (error) {
      console.error('Error generating link to credit agreement: ', error.message);
    }
  };

  useEffect(() => {
    downloadUSCreditAgreementPdf();
  }, []);

  return (
    <>
      {pdfLink && (
        <div className="credit-panel__row">
          <a href={pdfLink} className="credit-panel__link" target="_blank">
            Download credit agreement
          </a>
        </div>
      )}
    </>
  );
};

export default AgreementDownloadLink;
