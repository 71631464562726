/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

const Uploader = props => {
  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = event => {
        props.handleGotImage(event.target.result);
        setUploaded(true);
        remove();
      };
      reader.readAsDataURL(file);
    }
  };
  const [uploaded, setUploaded] = useState(false);
  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      inputContent={uploaded ? 'Click here to change image' : 'Drag and drop an image or click to browse'}
      autoUpload={false}
      SubmitButtonComponent={null}
      // maxFiles={1}
      accept="image/*"
    />
  );
};

export default Uploader;
