/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

function TakePicture({ handleCloseImage }) {
  const [error, setError] = useState(false);

  const handleTakePhoto = useCallback(
    uri => {
      handleCloseImage(uri);
    },
    [handleCloseImage],
  );

  const handleClose = useCallback(() => {
    handleCloseImage();
  }, [handleCloseImage]);

  const handleGetError = useCallback(error => {
    setError(error);
  });
  if (error) {
    return <div className="camera-error">An error has occurred: {error.message}</div>;
  }

  return (
    <div className="camera-modal">
      <div className="camera-card">
        <Camera onTakePhotoAnimationDone={handleTakePhoto} onCameraError={handleGetError} isDisplayStartCameraError={false} isImageMirror={false} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          className="client-dashboard__button client-dashboard__button--ghost client-dashboard__button--round"
        >
          Close
        </Button>
      </div>
    </div>
  );
}

export default TakePicture;
