import React from 'react';

const CloseIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <line x1="0.353553" y1="0.646447" x2="19.3536" y2="19.6464" stroke="black" />
    <line y1="-0.5" x2="26.8701" y2="-0.5" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0 20)" stroke="black" />
  </svg>
);

export default CloseIcon;
