import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { MdDashboard } from 'react-icons/md';
import { FaUser, FaLock, FaUpload } from 'react-icons/fa';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ThemeProvider } from '@material-ui/core';

import { useFlag } from 'react-unleash-flags';
import Service from '../../api/AxiosService';
import { withRegion } from '../../helpers/withRegion';

import Dashboard from './Dashboard/Dashboard';
import VerificationPanel from './Profile/VerificationPanel';
import Account from './Profile/Account';
import VerifiedBuyerDashboard from './VerifiedBuyer/VerifiedBuyerDashboard';
import UploadDocument from './Upload/UploadDocument';

import '../../styles/components/ClientDashboard/ClientDashboard.scss';
import '../../styles/components/Shared/Status.scss';

import clientTheme from '../../styles/theme/clientTheme';

const ClientDashboard = ({ region }) => {
  const [isVerifiedBuyerClient, setIsVerifiedBuyerClient] = useState(false);
  const [verifiedBuyerStatus, setVerifiedBuyerStatus] = useState(false);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    lineOfCredit: {},

    expired: false,
    reviewUponCompletion: false,
    hasActivePurchase: false,
    approvedForCredit: false,
    verificationComplete: false,
    needToAcceptCreditAgreement: false,
    locCounterOffer: false,
    referredOrAlloyPending: false,
    locRejected: false,
    locExpired: false,
  });

  const [verificationStatus, setVerificationStatus] = useState(null);
  const [verificationDate, setVerificationDate] = useState(null);
  const [verificationType, setVerificationType] = useState(null);

  const loadProfile = async () => {
    try {
      const res = await Service.get('users/client_info');

      const hasOperativeLoC = res?.line_of_credit;
      const hasOperativeVBA = res?.VBA?.vba_status;

      // In rare edge cases a client might have neither a line of credit nor a VBA in play;
      // figure out the appropriate workflow to put them into.
      if (!hasOperativeVBA && !hasOperativeLoC) {
        if (res?.registration_reason === 'vba') {
          window.location = `/${region}/amvb_application`;
        } else {
          window.location = `/${region}/credit_application`;
        }
        return;
      }

      // Determine ID Verification status
      if (res.profile.ba_fv_verified_method && res.profile.ba_fv_verified_at) {
        setVerificationStatus('completed');
        setVerificationDate(res.profile.ba_fv_verified_at);
        setVerificationType(res.profile.ba_fv_verified_method);
      } else if (res.profile.ba_fv_initiated_at && res.profile.ba_fv_initiated_at) {
        setVerificationStatus('pending');
        setVerificationDate(res.profile.ba_fv_initiated_at);
        setVerificationType(res.profile.ba_fv_initiated_method);
      } else {
        setVerificationStatus('unbegun');
      }

      setProfileData({
        lineOfCredit: res.line_of_credit,
        locAlloyApplicationData: res.loc_alloy_application_data,
        expired: res.loc_expired,
        reviewUponCompletion: res.review_upon_completion,
        hasMadePurchases: res.has_made_purchases,
        verificationStatus,
        verificationType,
        verificationDate,
        email: res.profile.ba_username,
        mobile: res.profile.ba_sms_verification_number,
        secret_question: res.secret_questions[0]?.question,
        secret_question_id: res.secret_questions[0]?.id,
        questions: res.secret_questions,
        approvedForCredit: res.approved_for_credit ?? false,
        verificationComplete: res.verification_complete ?? false,
        needToAcceptCreditAgreement: res.need_to_accept_credit_agreement ?? false,
        locCounterOffer: res.loc_counter_offer ?? false,
        referredOrAlloyPending: (res.loc_referred || res.loc_alloy_pending) ?? false,
        locRejected: res.loc_rejected ?? false,
        locExpired: res.loc_expired ?? false,
        postHatchLoc: res.loc_hatch_era ?? false,
      });
      // Establish if user is a VB client
      setIsVerifiedBuyerClient(Boolean(res.VBA));
      // Set the status of the users VB application: 'verified' or 'pending'
      setVerifiedBuyerStatus(res.VBA);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompleteUpload = useCallback(() => {
    setActiveTab('dashboard');
    setLoading(true);
    window.scrollTo(0, 0);
    loadProfile();
  }, []);

  const changeTab = (tab) => {
    document.body.classList.remove(`tab-${activeTab}`);
    setActiveTab(tab);
    document.body.classList.add(`tab-${tab}`);
  };

  const loadDashboard = useCallback(() => {
    setLoading(true);
    document.body.classList.add(`tab-${activeTab}`);
    loadProfile();
  }, []);

  useEffect(() => {
    loadDashboard();
  }, []);

  const secureDocUpload = useFlag('secure_document_upload')?.enabled;

  if (loading) {
    return (
      <div className="nav--spacer client-dashboard">
        <div className="section container--first">
          <h1>Welcome</h1>
        </div>
        <div className="client-dashboard__tabs section">
          <div className="client-dashboard__tab active">
            <MdDashboard />
            Dashboard
          </div>
          <div className="client-dashboard__tab disabled">
            <FaUser />
            My Profile
          </div>
          <div className="client-dashboard__tab disabled">
            <FaLock />
            My Account
          </div>
          {secureDocUpload && (
            <div className="client-dashboard__tab disabled">
              <FaUpload />
              Uploads
            </div>
          )}
        </div>
        <div className="client-dashboard-inner">
          <div className="client-id-loading">
            <ProgressSpinner />
          </div>
        </div>
      </div>
    );
  }

  const {
    lineOfCredit,
    locAlloyApplicationData,
    expired,
    reviewUponCompletion,
    hasMadePurchases,
    approvedForCredit,
    verificationComplete,
    needToAcceptCreditAgreement,
    locCounterOffer,
    referredOrAlloyPending,
    locRejected,
    locExpired,
    postHatchLoc,
    email,
  } = profileData;

  return (
    <ThemeProvider theme={clientTheme}>
      <motion.div className="nav--spacer client-dashboard" animate={{ opacity: [0, 1] }} transition={{ duration: 0.2, ease: 'easeOut', delay: 0 }}>
        <div className="section container--first">
          <h1>{lineOfCredit ? `Welcome ${lineOfCredit.first_name}` : 'Welcome'}</h1>
        </div>
        <div className="client-dashboard__tabs section">
          <div
            role="button"
            aria-hidden="true"
            className={`client-dashboard__tab ${activeTab === 'dashboard' ? 'active' : ''}`}
            onClick={() => changeTab('dashboard')}
          >
            <MdDashboard />
            Dashboard
          </div>
          <div
            role="button"
            aria-hidden="true"
            className={`client-dashboard__tab ${activeTab === 'profile' ? 'active' : ''}`}
            onClick={() => changeTab('profile')}
          >
            <FaUser />
            My Profile
          </div>

          <div
            role="button"
            aria-hidden="true"
            className={`client-dashboard__tab ${activeTab === 'account' ? 'active' : ''}`}
            onClick={() => changeTab('account')}
          >
            <FaLock />
            My Account
          </div>
          {secureDocUpload && (
            <div
              id="secure_upload_id"
              role="button"
              aria-hidden="true"
              className={`client-dashboard__tab ${activeTab === 'upload' ? 'active' : ''}`}
              onClick={() => changeTab('upload')}
            >
              <FaUpload />
              Uploads
            </div>
          )}
        </div>

        <div className="client-dashboard-inner">
          {lineOfCredit && (
            <>
              {activeTab === 'dashboard' && (
                <Dashboard
                  reviewUponCompletion={reviewUponCompletion}
                  expired={expired}
                  hasMadePurchases={hasMadePurchases}
                  lineOfCredit={lineOfCredit}
                  locAlloyApplicationData={locAlloyApplicationData}
                  loading={loading}
                  region={region}
                  changeTab={changeTab}
                  verificationStatus={verificationStatus}
                  verificationDate={verificationDate}
                  verificationType={verificationType}
                  isVerifiedBuyerClient={isVerifiedBuyerClient}
                  verifiedBuyerStatus={verifiedBuyerStatus}
                  approvedForCredit={approvedForCredit}
                  verificationComplete={verificationComplete}
                  needToAcceptCreditAgreement={needToAcceptCreditAgreement}
                  locCounterOffer={locCounterOffer}
                  referredOrAlloyPending={referredOrAlloyPending}
                  locRejected={locRejected}
                  locExpired={locExpired}
                  postHatchLoc={postHatchLoc}
                  clientEmail={email}
                  loadDashboard={loadDashboard}
                />
              )}
            </>
          )}

          {activeTab === 'profile' && verificationStatus === 'unbegun' && (
            <VerificationPanel region={region} lineOfCredit={lineOfCredit} handleCompleteUpload={handleCompleteUpload} />
          )}
          {activeTab === 'profile' && verificationStatus === 'pending' && (
            <div className="section">
              <h3>{`Your ID verification with ${verificationType.toLowerCase()} is pending.`}</h3>
              <p>Your ID should be confirmed within 24 hours, please contact Art Money if you have any concerns about the status of your ID verification.</p>
            </div>
          )}
          {activeTab === 'account' && <Account profile={profileData} loadProfile={loadProfile} />}
          {activeTab === 'upload' && <UploadDocument />}

          {!lineOfCredit &&
            (activeTab === 'dashboard' && isVerifiedBuyerClient ? (
              <VerifiedBuyerDashboard status={verifiedBuyerStatus} />
            ) : (
              activeTab === 'dashboard' && <h3 className="section client-dashboard-inner__wrap">Coming Soon: Art Money Verified Buyer</h3>
            ))}
        </div>
      </motion.div>
    </ThemeProvider>
  );
};

ClientDashboard.propTypes = {
  region: PropTypes.string.isRequired,
};

export default withRegion(ClientDashboard);
