import axios from 'axios';

// we need a local axios service, as this request is sent to a different url than the main api
const AxiosService = axios.create({
  baseURL: process.env.REACT_APP_APP_WEBSITE,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true,
});

const SUCCESS_MESSAGE = 'Email sent';
const ERROR_MESSAGE = "We're sorry, there was a problem sending the email. Please try again later, or contact Art Money for assistance.";

const emailCreditAgreement = async (clientEmail, pdfFilepath, config, setIsEmailing, setHasEmailErrored, setEmailMessage) => {
  const formData = new FormData();
  formData.append('email', clientEmail);
  formData.append('credit_agreement_file', pdfFilepath);
  formData.append(Site.csrf_token, Site.csrf_hash);

  AxiosService.post('/credit_agreement/email_agreement', formData, config)
    .then((response) => {
      if (response?.data?.errors?.length) {
        setIsEmailing(false);
        setHasEmailErrored(true);
        setEmailMessage(ERROR_MESSAGE);
        return;
      }
      setIsEmailing(false);
      setHasEmailErrored(false);
      setEmailMessage(SUCCESS_MESSAGE);
    })
    .catch((error) => {
      setIsEmailing(false);
      setHasEmailErrored(true);
      setEmailMessage(ERROR_MESSAGE);
    });
};

export default emailCreditAgreement;
