const getDashboardStatus = ({
  approvedForCredit,
  verificationPending,
  verificationComplete,
  needToAcceptCreditAgreement,
  locCounterOffer,
  referredOrAlloyPending,
  locRejected,
  locExpired,
  postHatchLoc,
  countryCode,
}) => {
  let showReadyToArtMoney = false;
  let showOneMoreImportantStep = false;
  let showMakeAnOffer = false;
  let showAcceptAgreementState = false;
  let showOneMomentWhileWeReview = false;
  let showUnableToOfferYouCredit = false;
  let showCreditHasExpired = false;

  let showBuyButton = false;
  let showAvailableCredit = false;
  let showApplyForCredit = false;
  let showNeedToUpdate = false;
  let showAgreementDownloadLink = false;

  const isPostHatchOrNonUSLoc = postHatchLoc || countryCode !== 'us';
  const isPreHatchUSLoc = !postHatchLoc && countryCode === 'us';

  if (needToAcceptCreditAgreement && isPostHatchOrNonUSLoc) {
    if (locCounterOffer) {
      showMakeAnOffer = true;
    } else {
      showAcceptAgreementState = true;
    }
  } else if (approvedForCredit && isPostHatchOrNonUSLoc) {
    showAvailableCredit = true;
    if (countryCode === 'us') {
      showAgreementDownloadLink = true;
    }
    if (verificationPending || verificationComplete) {
      showReadyToArtMoney = true;
      showBuyButton = true;
    } else {
      showOneMoreImportantStep = true;
    }
  } else if (referredOrAlloyPending && isPostHatchOrNonUSLoc) {
    showOneMomentWhileWeReview = true;
  } else if (locRejected) {
    showUnableToOfferYouCredit = true;
  } else if (locExpired) {
    showCreditHasExpired = true;
    showAvailableCredit = true;
    showApplyForCredit = true;
    if (countryCode === 'us') {
      showAgreementDownloadLink = true;
    }
  } else if ((needToAcceptCreditAgreement || approvedForCredit || referredOrAlloyPending) && isPreHatchUSLoc) {
    showNeedToUpdate = true;
  } else {
    return false;
  }

  return {
    showReadyToArtMoney,
    showOneMoreImportantStep,
    showMakeAnOffer,
    showAcceptAgreementState,
    showOneMomentWhileWeReview,
    showUnableToOfferYouCredit,
    showCreditHasExpired,
    showBuyButton,
    showAvailableCredit,
    showApplyForCredit,
    showNeedToUpdate,
    showAgreementDownloadLink,
  };
};

export default getDashboardStatus;
