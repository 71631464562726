import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Message } from 'primereact/message';
import AxiosService from '../../../api/AxiosService';

import Loading from '../../Shared/Loading';

function OnFido({ handleCompleteUpload, config }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosService.post('onfido/create_applicant_sdk_token', undefined, config)
      .then((res) => {
        const { token } = res.data;
        const r = window.Onfido.init({
          useModal: false,
          token,
          onError(err) {
            console.log('ONFIDO ERROR');
            console.log(err);
          },
          onComplete(documents) {
            // callback for when everything is complete
            AxiosService.post('onfido/create_fv_check', { documents }, config)
              .then(() => handleCompleteUpload())
              .catch((err) => console.error(err));
          },
          steps: [
            'welcome',
            'document',
            'face',
            {
              type: 'complete',
              options: {
                message: 'Verification Submitted',
                submessage: 'We will notify you once the verification is completed.',
              },
            },
          ],
        });

        setLoading(false);
      })
      .catch((err) => {
        console.error('onFido error is: ', err);
        setError(
          "Sorry, we're not able to connect to the the verification service right now. Please try again later, or use one of the other identification options.",
        );
      });
  }, []);

  if (error) {
    return <Message severity="error" text={error} style={{ position: 'relative' }} />;
  }
  return <div id="onfido-mount">{loading && <Loading size={80} />}</div>;
}

OnFido.propTypes = {
  handleCompleteUpload: PropTypes.func.isRequired,
  config: PropTypes.object,
};

OnFido.defaultProps = {
  config: {},
};

export default OnFido;
