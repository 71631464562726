/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* global Site */
import React from 'react';
import ReactDOM from 'react-dom';
import { FlagsProvider } from 'react-unleash-flags';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import ClientDashboard from 'components/ClientDashboard';
import BuyersPremium from 'components/BuyersPremium';
import FormContext from './helpers/FormContext';

import Footer from './components/Footer';
import configureStore from './config/configureStore';
// const ClientDashboard = loadableLayout(() => import('components/ClientDashboard'))();

import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './styles/theme/theme.scss';
import './styles/theme/_messages.scss';
import flagConfig from './helpers/flagConfig';

const container = document.getElementById('react-footer');
const clientDashContainer = document.getElementById('client-dashboard-wrapper');
const buyersPremiumContainer = document.getElementById('buyers-premium-wrapper');

const storeData = Site.defaultStore;
const store = configureStore(storeData);

Site.reduxstore = store;

const TheActuaLFooter = ({ match }) => <Footer key={match.params.region} noRouter />;

const render = () => {
  if (container) {
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <Route path="/:region" component={TheActuaLFooter} />
        </BrowserRouter>
      </Provider>,
      container,
    );
  }

  if (clientDashContainer) {
    ReactDOM.render(
      <FlagsProvider config={flagConfig}>
        <Provider store={store}>
          <BrowserRouter>
            <Route path="/:region" component={ClientDashboard} />
          </BrowserRouter>
        </Provider>
      </FlagsProvider>,
      clientDashContainer,
    );
  }

  if (buyersPremiumContainer) {
    ReactDOM.render(
      <FlagsProvider config={flagConfig}>
        <Provider store={store}>
          <FormContext.Provider value="Buyer's Premium purchase request">
            <BrowserRouter>
              <Route path="/:region" component={BuyersPremium} />
            </BrowserRouter>
          </FormContext.Provider>
        </Provider>
      </FlagsProvider>,
      buyersPremiumContainer,
    );
  }
};

store.subscribe(render);
render();
