/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import './styles.scss';
import { withRegion } from '../../../helpers/withRegion';
import CloseIcon from '../../Icons/CloseIcon';
import Card from './Card';
import useGoogleAnalyticsEventTracking from '../../../hooks/useGoogleAnalyticsEventTracking';

const BuyArtworkPopup = ({ handleCloseModal, region }) => {
  const handlePreventClose = useCallback((event) => {
    event.stopPropagation();
  }, []);
  const { sendCustomFormStartEvent } = useGoogleAnalyticsEventTracking();

  useEffect(() => sendCustomFormStartEvent(), []);

  return createPortal(
    <div className="artwork-popup-modal__background" onClick={handleCloseModal}>
      <div className="status-modal__bg" onClick={handlePreventClose}>
        <div className="artwork-popup-modal__header">
          <small className="artwork-popup-modal__breadcrumbs">Buy artwork &gt; Choose</small>
          <button type="button" className="status-modal__close" onClick={handleCloseModal} aria-label="close popup">
            <CloseIcon />
          </button>
        </div>
        <div className="artwork-popup-modal__body">
          <p className="artwork-popup-modal__instructions">Choose which Art Money product you are using for this purchase.</p>
        </div>
        <div className="artwork-popup-modal__cards">
          <Card
            name="Interest Free"
            description="I'm paying over 10 months, interest-free from a partner gallery."
            learnMoreUrl="https://www.artmoney.com/products/interest-free"
            buyUrl={`/${region}/loans/create`}
            backgroundColor="#96A4B5"
          />
          <Card
            name="Buyer's Premium"
            description="I'm paying over time with a flat monthly fee from any art seller."
            learnMoreUrl="https://www.artmoney.com/products/buyers-premium"
            buyUrl={`/${region}/set_up_buyers_premium_purchase`}
            backgroundColor="#6A4344"
          />
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default withRegion(BuyArtworkPopup);
