import React from 'react';

const NotAllowedError = () => (
  <section>
    <div className="row">
      <div className="medium-8 medium-offset-2 columns">
        ERROR: Buyer&apos;s Premium purchase setup is currently available to credit-approved Art Money buyers in Australia only. If you have Art Money in
        another country, please contact Art Money directly about organising a Buyer&apos;s Premium purchase.
      </div>
    </div>
  </section>
);

export default NotAllowedError;
