import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OneMomentWhileWeReview from './OneMomentWhileWeReview';
import Loading from '../Loading';

import emailCreditAgreement from '../../../helpers/emailCreditAgreement';
import getCreditAgreementPdf from '../../../helpers/getCreditAgreementPdf';
import handleAcceptCreditAgreement from '../../../helpers/handleAcceptCreditAgreement';

const AcceptAgreementState = ({ locId, approvedCredit, clientEmail, isLoggedIn, isVerified, config, hasAcceptedCallback }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfFilepath, setPdfFilepath] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasAccepted, setHasAccepted] = useState(false);
  const [isEmailing, setIsEmailing] = useState(false);
  const [hasEmailErrored, setHasEmailErrored] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);

  const doEmailCreditAgreement = () => {
    setIsEmailing(true);
    emailCreditAgreement(clientEmail, pdfFilepath, config, setIsEmailing, setHasEmailErrored, setEmailMessage);
  };

  const acceptanceCallback = () => {
    if (hasAcceptedCallback) {
      hasAcceptedCallback();
      return;
    }

    setHasAccepted(true);
  };

  useEffect(() => {
    getCreditAgreementPdf(locId, config, setPdfUrl, setPdfFilepath, setErrorMessage);
  }, []);

  if (hasAccepted && (!isLoggedIn || !isVerified)) {
    return (
      <>
        <h2>Congratulations, you have Art Money!</h2>
        <OneMomentWhileWeReview clientEmail={clientEmail} isLoggedIn={isLoggedIn} isVerified={isVerified} isUnderReview={false} config={config} />
      </>
    );
  }

  return (
    <>
      <h2>Congratulations, you have Art Money!</h2>
      <p>You have been approved for the line of credit you requested, of {approvedCredit}.</p>
      <p>Please review and accept the terms of the Agreement and Important Disclosures document below.</p>
      {pdfUrl && (
        <>
          <p>
            <iframe src={pdfUrl} width="100%" height="500px" />
          </p>
          <form onSubmit={(event) => handleAcceptCreditAgreement(event, locId, pdfFilepath, config, acceptanceCallback, setErrorMessage)}>
            <label data-alt-label="Accepting Terms and Conditions" className="checkbox full_width" htmlFor="accept_terms">
              <input type="checkbox" name="accept_terms" id="accept_terms" required />I accept the offer and acknowledge I have read and understood the terms of
              the Agreement and Important Disclosures document and agree to be bound by them.
            </label>
            <button className="btn--pri button__secondary left-margin text--center" disabled={isEmailing} onClick={doEmailCreditAgreement}>
              Send Credit Agreement by email <i className="pi pi-spin pi-spinner"></i>
            </button>
            {emailMessage && <p className={'p-messages ' + (hasEmailErrored ? 'p-messages-error' : 'p-messages-success')}>{emailMessage}</p>}
            <button type="submit" className="btn--pri left-margin dark text--center">
              Accept Credit Agreement
            </button>
          </form>
        </>
      )}
      {errorMessage && <p className="p-messages p-messages-error">{errorMessage}</p>}
      {!pdfUrl && !errorMessage && <Loading size={80} />}
    </>
  );
};

AcceptAgreementState.defaultProps = {
  locId: '0',
  approvedCredit: '0',
  isLoggedIn: true,
  isVerified: true,
  clientEmail: '',
  config: {},
  hasAcceptedCallback: undefined,
};

AcceptAgreementState.propTypes = {
  locId: PropTypes.string,
  approvedCredit: PropTypes.object || PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isVerified: PropTypes.bool,
  clientEmail: PropTypes.string,
  config: PropTypes.object,
  hasAcceptedCallback: PropTypes.func,
};

export default AcceptAgreementState;
