import React from 'react';
import PropTypes from 'prop-types';

const OneMoreImportantStep = ({ approvedCredit, onClick }) => {
  return (
    <>
      <h2>Congratulations! You have Art Money credit!</h2>
      <p>Welcome to Art Money, your currently approved credit is {approvedCredit}.</p>
      <p>
        One more important step before purchasing your art, we&apos;ll first need to{' '}
        <button className="button-as-link" type="button" onClick={onClick}>
          verify your identity
        </button>
        . This is a one-off security step to protect you prior to making your first Art Money purchase.
      </p>
      <p>
        <button onClick={onClick} type="button" className="btn--pri dark text--center">
          Verify your ID
        </button>
      </p>
    </>
  );
};

OneMoreImportantStep.defaultProps = {
  approvedCredit: '0',
  onClick: () => {},
};

OneMoreImportantStep.propTypes = {
  approvedCredit: PropTypes.object || PropTypes.string,
  onClick: PropTypes.func,
};

export default OneMoreImportantStep;
