/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const statusStrings = (status, approvedCredit = null, region = null, firstName, changeTab, verificationComplete, verificationType, verificationDate) => {
  let message;
  switch (status) {
    case 'approved_verified_first_time_buyer':
      message = (
        <div>
          {/* Disabled while purchasing is shut down. */}
          {/* <h3>Ready to Art Money it?</h3>
          <p>Complete a new Art Money purchase.</p> */}
        </div>
      );
      break;

    case 'approved_verification_pending_first_time_buyer':
      message = (
        <div>
          <h3>Congratulations! You have Art Money credit!</h3>
          <p>Welcome to Art Money, your currently approved credit is {approvedCredit}.</p>
          <p>{`Your ID verification request, submitted at ${moment(verificationDate).format(
            'D MMM YYYY',
          )} using the ${verificationType} option, is currently pending.`}</p>
        </div>
      );
      break;

    case 'approved_unverified_first_time_buyer':
      message = (
        <div>
          <h3>Congratulations! You have Art Money credit!</h3>
          <p>Welcome to Art Money, your currently approved credit is {approvedCredit}.</p>
          <p>
            One more important step before purchasing your art, we’ll first need to{' '}
            <span className="client-dashboard-inner__link" role="button" onClick={() => changeTab('profile')} aria-hidden="true">
              verify your identity
            </span>
            . This is a one-off security step to protect you prior to making your first Art Money purchase.
          </p>
        </div>
      );
      break;
    case 'repeat_customer':
      message = (
        <div>
          {/* Disabled while purchasing is shut down. */}
          {/* <h3>Ready to Art Money it?</h3>
          <p>Complete a new Art Money purchase.</p> */}
        </div>
      );
      break;
    case 'expired':
      message = (
        <div>
          <h3>Your credit has expired</h3>
          <p>Welcome back, it looks like your previously approved credit has expired with time.</p>
          <p>
            To purchase with Art Money, please{' '}
            <Link to={`/${region}/eligibility`} target="_self">
              re-apply for credit
            </Link>
            , in the meantime should you have any questions feel free to <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/contact`}>contact us.</a>
          </p>
        </div>
      );
      break;
    case 'referredOrAlloyPending':
      message = (
        <div>
          <h3>One moment while we review your credit application</h3>
          <p>
            Our objective is to approve you asap, within a few hours or by next business day. Sometimes we may need a little more information, in which case we
            will contact you.
          </p>
          {!verificationComplete && !verificationType && (
            <p>
              Whilst you wait, we recommend you{' '}
              <span className="client-dashboard-inner__link" role="button" onClick={() => changeTab('profile')} aria-hidden="true">
                verify your identity
              </span>
              , a one-off security step to protect you prior to making your first Art Money purchase.
            </p>
          )}
        </div>
      );
      break;
    case 'review_upon_completion':
      message = (
        <div>
          <h3>Please contact us</h3>
          <p>
            To make your next purchase it looks like we need a little more information,{' '}
            <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/contact`}>please contact us here.</a>
          </p>
        </div>
      );
      break;
    default:
      message = '';
  }

  return {
    message,
  };
};

export default statusStrings;
