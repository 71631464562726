import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import VerifiedBuyerLogo from '../../../../site/assets/images/logos/AM-verified-buyer-logo.png';

const VerifiedBuyerStatus = ({ status, region }) => {
  const verified = status?.vba_status === 'verified';
  const pending = status?.vba_status === 'pending';
  const not_verified = status?.vba_status === 'not verified';

  return (
    <div className="vb-panel">
      <div className="vb-panel__wrap">
        <div className={`vb-panel__logo ${not_verified || pending ? 'pending' : ''}`}>
          <img src={VerifiedBuyerLogo} alt="Verified Buyer Logo" />
        </div>
        <div className="vb-panel__status">
          <div className="vb-panel__heading">STATUS</div>
          <div className="vb-panel__body">
            {pending || not_verified ? (
              <span>Verification Pending</span>
            ) : (
              <span>
                Verified until <br /> {status?.vba_expiry}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

VerifiedBuyerStatus.propTypes = {
  region: PropTypes.string,
  status: PropTypes.object,
};

export default VerifiedBuyerStatus;
