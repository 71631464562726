import React from 'react';
import PropTypes from 'prop-types';
import { increaseCreditLink } from '../../../helpers/links';

const CreditHasExpired = ({ region }) => {
  return (
    <>
      <h2>Your credit has expired</h2>
      <p>Welcome back, it looks like your previously approved credit has expired with time.</p>
      <p>
        To purchase with Art Money, please{` `}
        <a href={increaseCreditLink(region)} target="_self">
          re-apply for credit
        </a>
        , in the meantime should you have any questions feel free to <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/contact`}>contact us.</a>
      </p>
    </>
  );
};

CreditHasExpired.defaultProps = {};

CreditHasExpired.propTypes = {
  region: PropTypes.string.isRequired,
};

export default CreditHasExpired;
