import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import ReactFlagsSelect from 'react-flags-select';

import { submitActiveRegion, mapRegionCodeToUrlCode } from 'actions/region';

import Logo from '../../../site/assets/images/art-money-logo-white.png';
import FacebookLogo from '../../../site/assets/images/icon-facebook.svg';
import TwitterLogo from '../../../site/assets/images/icon-twitter.svg';
import InstagramLogo from '../../../site/assets/images/icon-instagram.svg';
import ArtInnovatorsAlliance from '../../../site/assets/images/logos/art-innovators-alliance.svg';

import 'styles/components/Footer.scss';
import 'styles/components/CookieConsent.scss';

const FooterLink = ({ region, children, to, noRouter, ...props }) => (
  <li className="footer__item">
    {noRouter ? (
      <a href={`${Site.base_url}${region}/${to}`} {...props} className="footer__link">
        {children}
      </a>
    ) : (
      <NavLink to={`/${region}/${to}`} {...props} className="footer__link">
        {children}
      </NavLink>
    )}
  </li>
);

class Footer extends React.Component {
  state = {
    redirectTo: false,
  };

  onSelectCountry = async countryCode => {
    const nextRegion = mapRegionCodeToUrlCode(countryCode.toLowerCase());

    try {
      await this.props.submitActiveRegion(nextRegion);

      this.setState({
        redirectTo: `/${nextRegion}/${window.location.pathname.slice(4)}`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { noRouter } = this.props;
    const { redirectTo } = this.state;

    const { region } = this.props.match.params;

    // Establish if page is react or non-react
    if (redirectTo && !redirectTo.includes(region)) {
      if (document.getElementById('react-app') !== null) {
        return <Redirect to={redirectTo} />;
      }
      window.location.href = redirectTo;
      return null;
    }

    const instagramLink = 'http://www.instagram.com/artmoney.global';
    const facebookLink = 'https://www.facebook.com/artmoneyint/';
    const twitterLink = 'http://www.twitter.com/artmoney_global';
    const currentRegionCode = region === 'uk' ? 'GB' : (region || 'us').toUpperCase();

    return (
      <section className="footer__wrap">
        <div className="footer__links">
          <div className="footer__content" />
          <div className="footer__block footer__block--founding">
            <p className="footer__heading">Founding Member :</p>
            <img src={ArtInnovatorsAlliance} />
          </div>
          <div className="footer__block footer__block--terms">
            <div className="footer__dropdown">
              {console.log(currentRegionCode)}
              <ReactFlagsSelect
                countries={['US', 'AU', 'NZ', 'GB']}
                customLabels={{ US: 'US', AU: 'AU', NZ: 'NZ', GB: 'UK' }}
                selected={currentRegionCode}
                placeholder="Select Region"
                showSelectedLabel
                showOptionLabel
                onSelect={this.onSelectCountry}
                className="flag-select"
                selectButtonClassName="flag-select__btn"
                optionsSize={14}
              />
            </div>
            <div className="footer__row">
              <div className="footer__social">
                <a href={instagramLink} className="footer__link">
                  <img className="footer__icon" src={InstagramLogo} />
                </a>
                <a href={facebookLink} className="footer__link">
                  <img className="footer__icon" src={FacebookLogo} />
                </a>
                <a href={twitterLink} className="footer__link">
                  <img className="footer__icon" src={TwitterLogo} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="policies">
          <div className="policies__wrap">
            {region !== 'uk' && (
              <a target="_self" href={`${Site.base_url}${region}/terms-of-use`} className="footer__link">
                Terms of Use
              </a>
            )}
            <a target="_self" href={`${Site.base_url}${region}/website_privacy_policy`} className="footer__link">
              Website Privacy Policy
            </a>
            <a target="_self" href={`${Site.base_url}${region}/credit_privacy_policy`} className="footer__link">
              {region === 'us' ? 'Privacy Notice' : 'Credit Privacy Policy'}
            </a>
            {region !== 'us' && region !== 'uk' ? (
              <a target="_self" href={`${Site.base_url}${region}/credit-guide`} className="footer__link">
                Credit Guide
              </a>
            ) : (
              ''
            )}
            {region === 'au' && (
              <a target="_self" href={`${Site.base_url}${region}/target_market_determination`} className="footer__link">
                Target Market Determination
              </a>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = {
  submitActiveRegion,
};

export default withRouter(connect(null, mapDispatchToProps)(Footer));
