import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import OneMomentWhileWeReview from './OneMomentWhileWeReview';
import CounterOfferReasoning from '../DecisionReasoningModals/CounterOfferReasoning';
import Loading from '../Loading';

import emailCreditAgreement from '../../../helpers/emailCreditAgreement';
import getCreditAgreementPdf from '../../../helpers/getCreditAgreementPdf';
import handleAcceptCreditAgreement from '../../../helpers/handleAcceptCreditAgreement';

const MakeAnOffer = ({
  locId,
  clientEmail,
  isLoggedIn,
  isVerified,
  config,
  openModal,
  handleOpenModal,
  handleCloseModal,
  locAlloyApplicationData,
  currencySymbol,
  expiryDate,
  hasAcceptedCallback,
}) => {
  const [hasAccepted, setHasAccepted] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfFilepath, setPdfFilepath] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmailing, setIsEmailing] = useState(false);
  const [hasEmailErrored, setHasEmailErrored] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);

  const counterOfferAmount = locAlloyApplicationData?.counter_offer_amount ? locAlloyApplicationData.counter_offer_amount : 0;
  const outcomeReasons = locAlloyApplicationData?.outcome_reasons ? locAlloyApplicationData.outcome_reasons : [];
  const approvedCredit = <CurrencyFormat value={Math.round(counterOfferAmount)} displayType="text" thousandSeparator prefix={currencySymbol} />;

  const doEmailCreditAgreement = () => {
    setIsEmailing(true);
    emailCreditAgreement(clientEmail, pdfFilepath, config, setIsEmailing, setHasEmailErrored, setEmailMessage);
  };

  const acceptanceCallback = () => {
    if (hasAcceptedCallback) {
      hasAcceptedCallback();
      return;
    }

    setHasAccepted(true);
  };

  useEffect(() => {
    getCreditAgreementPdf(locId, config, setPdfUrl, setPdfFilepath, setErrorMessage);
  }, []);

  if (hasAccepted && (!isLoggedIn || !isVerified)) {
    return (
      <>
        <h2>Congratulations, you have Art Money!</h2>
        <OneMomentWhileWeReview clientEmail={clientEmail} isLoggedIn={isLoggedIn} isVerified={isVerified} isUnderReview={false} config={config} />
      </>
    );
  }

  return (
    <>
      <h2>We&apos;d like to make an offer...</h2>
      <p>You have been approved for Art Money credit of {approvedCredit}.</p>
      <p>Please review and accept the terms of the Agreement and Important Disclosures document below.</p>
      {pdfUrl && (
        <>
          <p>
            <iframe src={pdfUrl} width="100%" height="500px" />
          </p>
          <form onSubmit={(event) => handleAcceptCreditAgreement(event, locId, pdfFilepath, config, acceptanceCallback, setErrorMessage)}>
            <label data-alt-label="Accepting Terms and Conditions" className="checkbox full_width" htmlFor="accept_terms">
              <input type="checkbox" name="accept_terms" id="accept_terms" required />I accept the offer and acknowledge I have read and understood the terms of
              the Agreement and Important Disclosures document and agree to be bound by them.
            </label>
            <button className="btn--pri button__secondary left-margin text--center" disabled={isEmailing} onClick={doEmailCreditAgreement}>
              Send Credit Agreement by email
            </button>
            {emailMessage && <p className={'p-messages ' + (hasEmailErrored ? 'p-messages-error' : 'p-messages-success')}>{emailMessage}</p>}
            <button type="submit" className="btn--pri left-margin dark text--center">
              Accept Credit Agreement <i className="pi pi-spin pi-spinner"></i>
            </button>
          </form>
        </>
      )}
      {errorMessage && <p className="p-messages p-messages-error">{errorMessage}</p>}
      {!pdfUrl && !errorMessage && <Loading size={80} />}
      <p>This is lower than your credit request for the following reason{outcomeReasons.length > 1 && 's'}:</p>
      <p>
        <button onClick={() => handleOpenModal('counterOfferReasoning')} type="button" className="btn--pri left-margin dark text--center">
          <span className="p-button-text">View decision details</span>
        </button>
      </p>
      <CounterOfferReasoning
        isOpen={openModal === 'counterOfferReasoning'}
        handleCloseModal={handleCloseModal}
        approvedCredit={approvedCredit}
        locAlloyApplicationData={locAlloyApplicationData}
        expiryDate={expiryDate}
      />
    </>
  );
};

MakeAnOffer.defaultProps = {
  clientEmail: '',
  isLoggedIn: true,
  isVerified: true,
  config: {},
  hasAcceptedCallback: undefined,
};

MakeAnOffer.propTypes = {
  clientEmail: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isVerified: PropTypes.bool,
  config: PropTypes.object,
  openModal: PropTypes.string.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  expiryDate: PropTypes.string.isRequired,
  hasAcceptedCallback: PropTypes.func,
};

export default MakeAnOffer;
