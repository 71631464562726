import AxiosService from '../api/AxiosService';

const getCreditAgreementPdf = async (locId, config, setPdfUrl, setPdfFilepath, errorCallback) => {
  let pdf = '';
  try {
    pdf = await AxiosService.post('/lines_of_credit/' + locId + '/generate_credit_agreement', undefined, config);
    setPdfUrl(pdf?.credit_agreement_pdf_link);
    setPdfFilepath(pdf?.credit_agreement_pdf_filepath);
  } catch (error) {
    errorCallback(error.message);
  }
};

export default getCreditAgreementPdf;
