import React, { useState, useRef, useCallback } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { Messages } from 'primereact/messages';
import AxiosService from '../../../api/AxiosService';
import LoadingButton from '../../Buttons/LoadingButton';
import useHandleError from '../../../hooks/useHandleError';

const UploadDocument = () => {
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [addFiles, setAddFiles] = useState(false);

  useHandleError(errors);

  const uploadMessages = useRef();

  const handleUpload = useCallback(
    async files => {
      setLoading(true);
      setAddFiles(true);
      try {
        const obj = new FormData();
        files.forEach(data => {
          if (data.meta.status !== 'done') {
            throw new Error('Document upload failed');
          }
          obj.append('file[]', data.file);
        });

        uploadMessages.current.clear();

        const result = await AxiosService.post('/upload_document', obj);
        if (result.result === 'success') {
          files.forEach(f => f.remove());
          uploadMessages.current?.show({
            severity: 'success',
            detail: result.message,
            life: 10000,
          });
        }
      } catch (e) {
        setErrors({
          error: e,
          setAlert: uploadMessages.current,
          overrideDefaultMessage: 'Sorry, your document upload was not successful. Please try again, or contact Art Money.',
        });
      } finally {
        setLoading(false);
        setAddFiles(false);
      }
    },
    [uploadMessages],
  );

  return (
    <div className="documentuploadpanel row">
      <div>
        <Messages
          ref={el => {
            uploadMessages.current = el;
          }}
          className="uploadMessage"
        />
      </div>
      <p className="uploadMessage">
        If you have received a request from Art Money to provide additional documentation for your application, please upload here via this secure portal.
      </p>
      <Dropzone
        inputContent="Drag and drop documents or click to browse"
        inputWithFilesContent="Add more files"
        autoUpload={false}
        onSubmit={handleUpload}
        accept="*"
        disabled={addFiles}
        SubmitButtonComponent={({ onSubmit, files }) => (
          <LoadingButton containerProps={{}} loading={isLoading} onClick={() => onSubmit(files)}>
            Upload
          </LoadingButton>
        )}
      />
    </div>
  );
};

export default UploadDocument;
