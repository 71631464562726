import React from 'react';
import { Modal } from '@material-ui/core';
import CloseIcon from '../Icons/CloseIcon';
import PropTypes from 'prop-types';
import OnFido from '../ClientDashboard/Profile/Onfido';

function OnFidoModal({ isOpen, handleCloseModal, handleOnFidoComplete, config }) {
  return (
    <Modal className="status-modal" open={isOpen} onClose={handleCloseModal} aria-labelledby="modal-title">
      <div className="row">
        <div className="status-modal__bg">
          <button type="button" className="status-modal__close float-right" onClick={handleCloseModal} aria-label="close popup">
            <CloseIcon />
          </button>
          <OnFido handleCompleteUpload={handleOnFidoComplete} config={config} />
        </div>
      </div>
    </Modal>
  );
}

OnFidoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleOnFidoComplete: PropTypes.func.isRequired,
  config: PropTypes.object,
};

OnFidoModal.defaultProps = {
  config: {},
};

export default OnFidoModal;
