import React from 'react';
import PropTypes from 'prop-types';
import DeclineReasoning from '../DecisionReasoningModals/DeclineReasoning';

const UnableToOfferYouCredit = ({isLoggedIn, clientEmail, openModal, handleOpenModal, handleCloseModal, locAlloyApplicationData, handleCreditApplication}) => {
  const outcomeReasons = locAlloyApplicationData.outcome_reasons ? locAlloyApplicationData.outcome_reasons : [];

  return (
    <>
      <h2>Unable to offer you credit</h2>
      <p>We regret we&apos;re unable to approve your credit request at this time.</p>
      <p>Please click below to review information related to our decision.</p>
      <p>
        <button onClick={() => handleOpenModal('declineReasoning')} type="button" className="btn--pri dark left-margin text--center">
          <span className="p-button-text">View decision details</span>
        </button>
      </p>
      {isLoggedIn ? (
        <>
          <p>If your circumstances have changed since your last application, please feel free to re-apply.</p>
          <p>
            <button onClick={handleCreditApplication} type="button" className="btn--pri dark left-margin text--center">
              <span className="p-button-text">Re-apply for Art Money</span>
            </button>
          </p>
        </>
      ) : (
        <>
          <h3>Your Art Money Account</h3>
          <p>
            Although we're not able to approve your credit request at the moment, we have created an Art Money account for you; if your circumstances change in
            the future, please feel free to use that account to re-apply.
          </p>
          <p>
            We've sent you an email at <b>{clientEmail}</b> with a link to set up your password and Art Money account.
          </p>
          <p>
            Please activate this as part of the verification process. (If you don't receive that email in the next 5 minutes please check your junk folder.)
          </p>
        </>
      )
      }
      <DeclineReasoning isOpen={openModal === 'declineReasoning'} handleCloseModal={handleCloseModal} locAlloyApplicationData={locAlloyApplicationData} />
    </>
  );
};

UnableToOfferYouCredit.defaultProps = {
  isLoggedIn: true,
  clientEmail: '',
};

UnableToOfferYouCredit.propTypes = {
  isLoggedIn: PropTypes.bool,
  clientEmail: PropTypes.string,
  openModal: PropTypes.string.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  locAlloyApplicationData: PropTypes.object.isRequired,
};

export default UnableToOfferYouCredit;
