import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  invoice_price: Yup.string().required('Invoice price is required'),
  payment_period: Yup.string().required('Payment period is required'),
  deposit_value: Yup.string().required('Deposit/first payment amount is required'),
  deposit_intent: Yup.string().required('Deposit/first payment intent is required'),
  seller: Yup.string().required('Art seller is required'),
  invoice_file: Yup.mixed().required('Invoice is required'),
  image1: Yup.mixed().required('At least one artwork image is required'),
  image2: Yup.mixed(),
  image3: Yup.mixed(),
});

const initialValues = {
  invoice_price: '',
  payment_period: '10',
  deposit_value: '',
  deposit_intent: 'I will pay a deposit/first payment to Art Money.',
  seller: '',
  invoice_file: '',
  image1: '',
  image2: '',
  image3: '',
};

export { validationSchema, initialValues };
