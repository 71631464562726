/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback } from 'react';
import { Formik, Field, useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useFlag } from 'react-unleash-flags';
import NumberFormat from 'react-number-format';

import Service from '../../api/AxiosService';
import { validationSchema, initialValues } from './formikValidationAndInitialValues';

import { setProfile } from '../../actions/profile';

import CustomDropzone from './CustomDropzone';
import Loading from '../Shared/Loading';
import NotAllowedError from './NotAllowedError';

import useGoogleAnalyticsEventTracking from '../../hooks/useGoogleAnalyticsEventTracking';

import './BuyersPremium.scss';

const Errors = ({ name, className }) => {
  const [, meta] = useField(name);
  const { sendCustomFormErrorEvent } = useGoogleAnalyticsEventTracking();

  useEffect(() => {
    if (meta.touched && meta.error) sendCustomFormErrorEvent(meta.error);
  }, [meta.touched, meta.error]);

  if (meta.touched && meta.error) {
    return <small className={`error ${className}`}>{meta.error}</small>;
  }
  return null;
};

const NumberField = ({ field, ...props }) => {
  return (
    <NumberFormat
      {...field}
      {...props}
      id={field.name}
      type="text"
      placeholder="0.00"
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

function BuyersPremium() {
  const billingcloudActive = useFlag('billingcloud_active')?.enabled;
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const { approved_for_credit: approvedForCredit, line_of_credit: lineOfCredit, profile } = useSelector((state) => state.profile);
  const { sendCustomFormStartEvent, sendCustomFormSubmitEvent } = useGoogleAnalyticsEventTracking();

  const isAU = lineOfCredit?.country_code === 'au';
  const isUS = lineOfCredit?.country_code === 'us';
  const showPaymentPeriodOptions = isAU;
  const isAllowedToViewBuyersPremium = approvedForCredit && (billingcloudActive ? isAU || isUS : isAU);

  useEffect(() => {
    sendCustomFormStartEvent();
  }, []);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const res = await Service.get('users/client_info');
        if (res.result !== 'success') {
          throw new Error('Failed to load user');
        }
        delete res.result;
        dispatch(setProfile(res));
      } catch (e) {
        // Fail quietly, as the user is not logged in
      } finally {
        setLoadingProfile(false);
      }
    };
    loadUser();
  }, []);

  const onSubmit = useCallback(
    async (values) => {
      setSubmitErrors([]);
      setIsSubmitting(true);
      try {
        const formData = new FormData();
        formData.append('invoice_price', +values.invoice_price.replaceAll(',', ''));
        formData.append('payment_period', values.payment_period);
        formData.append('deposit_intent', values.deposit_intent);
        formData.append('deposit_value', +values.deposit_value.replaceAll(',', ''));
        formData.append('seller', values.seller);
        formData.append('invoice_file', values.invoice_file, values.invoice_file.name);
        formData.append('am_files[]', values.image1, values.image1.name);
        if (values.image2) {
          formData.append('am_files[]', values.image2, values.image2.name);
        }
        if (values.image3) {
          formData.append('am_files[]', values.image3, values.image3.name);
        }
        await Service.post('purchases/submit_bp_purchase', formData);
        await sendCustomFormSubmitEvent({
          country: lineOfCredit.country_code,
          email: profile.ba_username,
          creditLimit: +values.invoice_price.replaceAll(',', ''),
        });
        setIsSuccess(true);
      } catch (e) {
        console.error(e);
        let errorMessages = [];

        if (e.message) {
          errorMessages.push(e.message);
        }

        if (e.errors) {
          const errorKeys = Object.keys(e.errors);
          errorMessages = errorKeys.map((key) => {
            const fieldName = key.replace(/_/g, ' ');
            return `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}: ${e.errors[key]}`;
          });
          setSubmitErrors(errorMessages);
        } else {
          if (!errorMessages.length) {
            errorMessages.push("Sorry, we couldn't submit your application at this time. Please try again later, or contact Art Money for assistance.");
          }
          setSubmitErrors(errorMessages);
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [lineOfCredit, profile],
  );

  if (loadingProfile) {
    return <Loading size={100} />;
  }
  if (!isAllowedToViewBuyersPremium) {
    return <NotAllowedError />;
  }
  if (isSuccess) {
    return (
      <section>
        <div className="row">
          <div className="medium-8 medium-offset-2 columns success-message">
            <h1 className="app_step_header no-border">
              Thanks for submitting your <span className="bp-text-color">Buyer&apos;s Premium</span> purchase
            </h1>
            {isAU && <p>To set up your monthly payments, we&apos;ll be in touch to obtain your bank or card details.</p>}
            {isAU && (
              <p>
                You&apos;ll receive an offer outlining the terms of your Buyer&apos;s Premium purchase via email. Once reviewed, accept the offer via your
                client dashboard.
              </p>
            )}
            {isUS && <p>We will email you an offer to review and accept online, or we will be in touch for more information.</p>}
            <p>
              <a href="/dashboard">Return to your account dashboard.</a>
            </p>
          </div>
        </div>
      </section>
    );
  }
  return (
    <>
      <section>
        <div className="row">
          <div className="medium-8 medium-offset-2 columns">
            <h1 className="app_step_header">
              Buy with <span className="bp-text-color">Buyer&apos;s Premium</span>
            </h1>

            <p>Art Money is not currently financing new sales.</p>
            <p>After almost 10 years of successful growth, we’ve made the difficult decision to pause credit for new purchases whilst we recapitalise.</p>
            <p>Should you have outstanding balances due to Art Money these will continue to be collected over time as per your contracted payment schedule.</p>
            <p>Thank you for your support and we apologise for the inconvenience.</p>
            <p>A more detailed note from our founder is <a href="https://paul-80301.medium.com/the-hardest-thing-359c1d91d6b7">here</a>.</p>
            <hr/>

            <p>I&apos;m paying over time with a flat monthly fee from any art seller.</p>
          </div>
        </div>
      </section>
      <section>
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {({ handleSubmit, errors, touched }) => {
            const hasErrors = (field) => errors[field] && touched[field];
            return (
              <form onSubmit={handleSubmit}>
                <div className="row field-gap-20">
                  <div className="medium-offset-2 medium-2 column">
                    <label htmlFor="invoice_price">Invoice price</label>
                  </div>
                  <div className="medium-3 columns end">
                    <div className="relative">
                      <span className="currency_prefix purchase_currency_prefix">$</span>
                      <Field component={NumberField} className={`text-right full_width ${hasErrors('invoice_price') ? 'error' : ''}`} name="invoice_price" />
                      <Errors name="invoice_price" />
                    </div>
                  </div>
                </div>
                <div className="row field-gap-30">
                  <div className="medium-offset-2 medium-2 column">
                    <label>Payment Period</label>
                  </div>
                  {showPaymentPeriodOptions && (
                    <div className="medium-3 columns end">
                      <div>
                        <label>
                          <Field type="radio" name="payment_period" value="6" /> 6 months
                        </label>
                      </div>
                      <div>
                        <label>
                          <Field type="radio" name="payment_period" value="10" /> 10 months
                        </label>
                      </div>
                      <div>
                        <label>
                          <Field type="radio" name="payment_period" value="18" /> 18 months
                        </label>
                      </div>
                      <Errors name="payment_period" />
                    </div>
                  )}
                  {!showPaymentPeriodOptions && (
                    <div className="medium-3 columns end">
                      <div>
                        <label>
                          <Field type="radio" name="payment_period" value="10" className="soft_hide" />
                          <strong>10 months</strong>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row field-gap-30">
                  <div className="medium-offset-2 medium-2 column">
                    <label>Deposit/first payment</label>
                    <div className="light deposit-minimum">Minimum 10%</div>
                  </div>
                  <div className="medium-6 columns end">
                    <div>
                      <label>
                        <Field type="radio" name="deposit_intent" value="I'm paying/have paid a deposit/first payment to the art seller." /> I&apos;m
                        paying/have paid a deposit/first payment to the art seller.
                      </label>
                    </div>
                    <div>
                      <label>
                        <Field type="radio" name="deposit_intent" id="toArtMoney" value="I will pay a deposit/first payment to Art Money." /> I will pay a
                        deposit/first payment to Art Money.
                      </label>
                    </div>
                    <Errors name="deposit_intent" />
                  </div>
                </div>
                <div className="row field-gap-20">
                  <div className="medium-offset-2 medium-2 column">
                    <label htmlFor="deposit_value">Deposit/first payment amount</label>
                  </div>
                  <div className="medium-3 columns end">
                    <div className="relative">
                      <span className="currency_prefix purchase_currency_prefix">$</span>
                      <Field component={NumberField} className={`text-right full_width ${hasErrors('deposit_value') ? 'error' : ''}`} name="deposit_value" />
                      <Errors name="deposit_value" />
                    </div>
                  </div>
                </div>
                <div className="row field-gap-20">
                  <div className="medium-offset-2 medium-2 column">
                    <label htmlFor="seller">Art seller</label>
                  </div>
                  <div className="medium-6 columns end">
                    <Field
                      className={`full_width ${hasErrors('seller') ? 'error' : ''}`}
                      type="text"
                      id="seller"
                      name="seller"
                      placeholder="Enter art seller name"
                    />
                    <Errors name="seller" />
                  </div>
                </div>
                <div className="row field-gap-20">
                  <div className="medium-offset-2 medium-2 column">
                    <label>Invoice</label>
                  </div>
                  <div className="medium-6 columns end">
                    <CustomDropzone text="Upload invoice" name="invoice_file" />
                    <Errors name="invoice_file" className="dropzone-error-margin" />
                  </div>
                </div>
                <div className="row field-gap-20">
                  <div className="medium-offset-2 medium-2 column">
                    <label>Artwork images</label>
                  </div>
                  <div className="medium-6 columns end">
                    <CustomDropzone text="Upload image" name="image1" />
                    <Errors name="image1" className="dropzone-error-margin" />
                    <CustomDropzone text="Upload image" name="image2" />
                    <CustomDropzone text="Upload image" name="image3" />
                  </div>
                </div>
                {!!submitErrors.length && (
                  <div className="row field-gap-20">
                    <div className="medium-8 medium-offset-2 columns end">
                      <div className="p-messages p-component p-messages-error p-messages-enter-done">
                        <div className="p-messages-wrapper">
                          <span className="p-messages-icon pi  pi-times" />
                          <ul>
                            {submitErrors.map((error) => (
                              <li key={error}>
                                <span className="p-messages-summary" />
                                <span className="p-messages-detail">
                                  <span>
                                    <p>{error}</p>
                                  </span>
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row field-gap-20">
                  <div className="medium-8 medium-offset-2 columns end">
                    <button className="btn--pri dark right submit-button-flex" type="submit">
                      Submit <i className={`pi ${isSubmitting ? 'pi-spin pi-spinner' : ''}`} />
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </section>
    </>
  );
}

export default BuyersPremium;
