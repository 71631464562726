import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function SharedCopyForReasoningModals({locAlloyApplicationData}) {
  const creditAgency = locAlloyApplicationData.credit_agency;
  const creditScoreInfo = locAlloyApplicationData.credit_score_info;
  const creditScoreDate = moment(creditScoreInfo.date).format('D MMMM yyyy');
  const scoreFactors = locAlloyApplicationData.score_factors;

  const CreditAgencyDetails = () => {
    return (
      <p>
        {creditAgency.long_name && (
          <>
            <b>{creditAgency.long_name}</b>
            <br />
          </>
        )}
        {creditAgency.address && (
          <>
            <b>{creditAgency.address}</b>
            <br />
          </>
        )}
        {creditAgency.phone_number && <b>Telephone Number: {creditAgency.phone_number}</b>}
      </p>
    );
  };

  return (
    <>
      <p>
        Our credit decision on your application was based in whole or in part on information obtained in a report from the consumer reporting agency listed
        below. You have the right under the Fair Credit Reporting Act to know the information contained in your credit file at the consumer reporting agency.
        The reporting agency played no part in our decision and is unable to supply specific reasons why we have denied credit to you. You also have a right to
        a free copy of your report from the reporting agency, if you request it no later than 60 days after you receive this notice. In addition, if you find
        that any information contained in the report you receive is inaccurate or incomplete, you have the right to dispute the matter with the reporting
        agency. Any questions regarding such information should be directed to the consumer reporting agency below:
      </p>
      <CreditAgencyDetails />
      <p>
        We also obtained your credit score from {creditAgency.short_name} and used it in making our credit decision. Your credit score is a number that reflects
        the information in your consumer report. Your credit score can change, depending on how the information in your consumer report changes.
      </p>
      <p>
        Your credit score: <b>{creditScoreInfo.score}</b>
        <br />
        Date: <b>{creditScoreDate}</b>
        <br />
        Scores range from a low of <b>{creditScoreInfo.range?.low}</b> to <b>{creditScoreInfo.range?.high}</b>.
      </p>
      <p>Key factors that adversely affected your credit score:</p>
      <ul>
        {Object.values(scoreFactors).map((factor, index) => (
          <li key={index}>{factor}</li>
        ))}
      </ul>
      <p>If you have any questions regarding your credit score or the information contained in your consumer report, you should contact TransUnion at:</p>
      <CreditAgencyDetails />
      <p>
        Art Money US is providing you this notification on behalf of Hatch Bank. Art Money offers consumer credit products through Hatch Bank.{' '}
        <b>
          If you have any questions regarding this notice please contact us at{' '}
          <a href="mailto:accounts@artmoney.com" target="_blank">
            accounts@artmoney.com
          </a>
        </b>
      </p>
      <p>
        <small>
          Notice: The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color,
          religion, national origin, sex, marital status, age (provided the applicant has the capacity to enter into a binding contract); because all or part of
          the applicant's income derives from any public assistance program; or because the applicant has in good faith exercised any right under the Consumer
          Credit Protection Act. The Federal agency that administers compliance with this law concerning this creditor is the Division of Depositor and Consumer
          Protection, National Center for Consumer and Depositor Assistance, Federal Deposit Insurance Corporation, 1100 Walnut Street, Box #11, Kansas City, MO
          64106.
        </small>
      </p>
    </>
  );
}

SharedCopyForReasoningModals.propTypes = {
  locAlloyApplicationData: PropTypes.object.isRequired,
};

export default SharedCopyForReasoningModals;
