/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef, useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Messages } from 'primereact/messages';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { Formik } from 'formik';

import AxiosService from '../../../api/AxiosService';
import useSecretQuestions from '../../../hooks/useSecretQuestions';
import useHandleError from '../../../hooks/useHandleError';
import TextInput from '../../ClientForms/TextInput';
import Select from '../../ClientForms/Select';
import { validatePasswordConfirmation } from '../../ClientForms/helpers';
import LoadingButton from '../../Buttons/LoadingButton';
import FormContext from '../../../helpers/FormContext';
import useGoogleAnalyticsEventTracking from '../../../hooks/useGoogleAnalyticsEventTracking';

const contactDetailsValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("This doesn't look like a valid email address")
    .required('This field is required'),
  mobile: Yup.string().required('Please enter your mobile number'),
  secret_answer: Yup.string().required('This field is required in order to update your details'),
});

const passwordValidationSchema = Yup.object().shape({
  password: Yup.string().required('Please enter a new password'),
  old_password: Yup.string().required('Please enter your old password'),
  password_confirmation: validatePasswordConfirmation,
});

const updateSecurityValidationSchema = Yup.object().shape({
  secret_custom: Yup.string().test({
    name: 'customQuestion',
    message: 'Please enter a security question.',
    test: (value, { parent }) => {
      if (parent.secret_question !== 'custom') {
        return true;
      }
      return Boolean(value?.length);
    },
  }),
  secret_question: Yup.string().required('Please select a question'),
  secret_answer: Yup.string().required('Please enter an answer'),
  password: Yup.string().required('Your password is required to change your security question'),
});

const updatePasswordInitialValues = {
  password: '',
  old_password: '',
  password_confirmation: '',
};

export default function Account({ profile, loadProfile }) {
  const [, securityQuestions] = useSecretQuestions();
  const contactDetailsMessages = useRef();
  const securityMessages = useRef();
  const updatePasswordMessages = useRef();

  const [disabled, setDisabled] = useState({});
  const [questions, setQuestions] = useState([]);
  const [errorData, setErrorData] = useState({});

  const { sendCustomFormSubmitEvent } = useGoogleAnalyticsEventTracking();

  useEffect(() => {
    if (profile.questions) {
      setQuestions(profile.questions);
    }
  }, [profile]);

  useHandleError(errorData);

  const updateContactDetails = async (values, { setErrors, resetForm }) => {
    contactDetailsMessages.current.clear();
    try {
      const result = await AxiosService.post('users/update_contact_details', values);
      await loadProfile();
      resetForm();
      contactDetailsMessages.current.show({
        severity: 'success',
        detail: result.message,
        life: 10000,
      });
    } catch (err) {
      setErrorData({
        error: err,
        fieldExists: field => values[field],
        setError: (field, fieldError) => setErrors({ [field]: fieldError }),
        setAlert: contactDetailsMessages.current,
        overrideDefaultMessage: 'An error has occurred, please try again.',
      });
    }
  };

  const updatePassword = async (values, { setErrors, resetForm }) => {
    updatePasswordMessages.current?.clear();
    try {
      const result = await AxiosService.post('users/update_password', values);
      updatePasswordMessages.current?.show({
        severity: 'success',
        detail: result.message,
        life: 10000,
      });
      resetForm();
      sendCustomFormSubmitEvent({
        name: 'Set a new password',
        email: profile.email,
      });
    } catch (err) {
      setErrorData({
        error: err,
        fieldExists: field => values[field],
        setError: (field, fieldError) => setErrors({ [field]: fieldError }),
        setAlert: updatePasswordMessages.current,
        overrideDefaultMessage: 'An error has occurred, please try again.',
      });
    }
  };

  const updateSecurity = useCallback(
    async (values, { setErrors, setFieldValue, resetForm }) => {
      try {
        securityMessages?.current?.clear();
        setDisabled(prev => ({ ...prev, [values.id]: true }));
        const submitValues = {
          answer: values.secret_answer,
          password: values.password,
          question: values.secret_question === 'custom' ? values.secret_custom : values.secret_question,
        };

        const result = await AxiosService.put(`auth/user_recovery/${values.id}`, submitValues);
        resetForm();
        setFieldValue('secret_question', values.secret_question);
        setFieldValue('secret_custom', values.secret_custom);
        securityMessages?.current?.show({
          severity: 'success',
          detail: result.message ?? 'Your question has been updated successfully',
          life: 5000,
        });
        await sendCustomFormSubmitEvent({
          name: 'Security question',
          email: profile.email,
        });
      } catch (err) {
        setErrorData({
          error: err,
          fieldExists: field => values[field],
          setError: (field, fieldError) => setErrors({ [field]: fieldError }),
          setAlert: securityMessages.current,
          overrideDefaultMessage: 'An error has occurred, please try again.',
        });
      } finally {
        setDisabled(prev => ({ ...prev, [values.id]: false }));
      }
    },
    [loadProfile, securityMessages, loadProfile],
  );

  const questionsMemo = useMemo(() => {
    if (!securityQuestions?.length || !questions?.length) {
      return null;
    }

    return (
      <table style={{ width: '100%' }}>
        <tbody>
          {questions.map(obj => {
            const selectedQuestion = securityQuestions.find(question => question.value === obj.question);
            const isCustom = !selectedQuestion;
            return (
              <Fragment key={obj.id}>
                <FormContext.Provider value="Security question">
                  <tr>
                    <td colSpan={2}>
                      <motion.div
                        style={{ paddingRight: 40 }}
                        animate={{
                          scale: [0.9, 1],
                          translateY: [-15, 0],
                          opacity: [0, 1],
                        }}
                        exit={{
                          scale: [1, 0.9],
                          translateY: [0, -15],
                          opacity: [1, 0],
                        }}
                        transition={{ duration: 0.5, ease: 'easeOut' }}
                      >
                        <Formik
                          onSubmit={updateSecurity}
                          validationSchema={updateSecurityValidationSchema}
                          initialValues={{
                            secret_question: isCustom ? 'custom' : selectedQuestion.label,
                            secret_custom: isCustom ? obj.question : undefined,
                            secret_answer: '',
                            password: '',
                            id: obj.id,
                          }}
                        >
                          {({ handleSubmit, values, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="small-12 columns">
                                  <label htmlFor="email" className="full_width b_spaced">
                                    <span className="label">
                                      Select Question <span className="light">*</span>
                                    </span>
                                    <Select name="secret_question" placeholder="Select a Question" options={securityQuestions} />
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                {values.secret_question === 'custom' && (
                                  <div className="form-input small-12 columns">
                                    <label htmlFor="email" className="full_width b_spaced">
                                      <span className="label">
                                        Custom Question <span className="light">*</span>
                                      </span>
                                      <TextInput name="secret_custom" />
                                    </label>
                                  </div>
                                )}
                                <div className="form-input small-12 columns">
                                  <label htmlFor="email" className="full_width b_spaced">
                                    <span className="label">
                                      Answer <span className="light">*</span>
                                    </span>
                                    <TextInput name="secret_answer" aria-required="true" />
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="form-input small-12 medium-6 columns">
                                  <label htmlFor="email" className="full_width b_spaced">
                                    <span className="label">
                                      Password <span className="light">*</span>
                                    </span>
                                    <TextInput name="password" type="password" />
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="small-6 columns">
                                  <LoadingButton containerProps={{}} disabled={disabled[values.id]} loading={isSubmitting} type="submit">
                                    {obj.id === 'new' ? 'Save' : 'Update'}
                                  </LoadingButton>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </motion.div>
                    </td>
                  </tr>
                </FormContext.Provider>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  }, [securityQuestions, questions, updateSecurity, disabled]);

  const securityQuestionMemo = useMemo(() => {
    if (!profile?.questions?.length) {
      return '';
    }
    if (profile?.questions?.length === 1) {
      return profile.questions[0].question || '';
    }
    return '';
  }, [profile.questions]);

  const initialValuesContactDetails = useMemo(
    () => ({
      email: profile.email,
      mobile: profile.mobile,
      secret_question_id: profile?.questions[0]?.id,
      secret_answer: '',
    }),
    [profile],
  );

  const questionsOptions = useMemo(() => profile.questions.map(option => ({ value: option.id, label: option.question, disabled: option.disabled })), [
    profile.questions,
  ]);

  return (
    <div className="account-panel row account">
      <div className="medium-8 columns medium-offset-2">
        <h1 className="app_step_header">Update my account</h1>

        <h2 className="app_step_subhead">Contact Details</h2>
        <p>To update your phone and email address you must also provide the answer to your security question</p>
        <Messages
          ref={el => {
            contactDetailsMessages.current = el;
          }}
        />
        <Formik
          enableReinitialize
          validationSchema={contactDetailsValidationSchema}
          initialValues={initialValuesContactDetails}
          onSubmit={updateContactDetails}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-input small-12 medium-4 columns">
                  <label htmlFor="email" className="full_width b_spaced">
                    <span className="label">
                      Email address <span className="light">*</span>
                    </span>
                    <TextInput name="email" />
                  </label>
                </div>
                <div className="form-input small-12 medium-4 columns end">
                  <label data-alt-label="Mobile number" htmlFor="mobile" className="full_width b_spaced">
                    <span className="label">
                      Mobile Number <span className="light">*</span>
                    </span>
                    <TextInput name="mobile" aria-required="true" />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="form-input small-12 medium-8 columns">
                  <label htmlFor="secret_answer" className="full_width b_spaced">
                    <span className="label">
                      <strong>Security question: </strong>
                      {securityQuestionMemo}
                    </span>
                    {profile?.questions?.length > 1 && (
                      <>
                        <span className="label">
                          Question <span className="light">*</span>
                        </span>
                        <Select placeholder="Select a Question" name="secret_question_id" options={questionsOptions} />
                      </>
                    )}
                    <span className="label">
                      Answer <span className="light">*</span>
                    </span>
                    <TextInput name="secret_answer" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="small-12 medium-12 columns">
                  <LoadingButton containerProps={{}} loading={isSubmitting} type="submit">
                    Update contact details
                  </LoadingButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <hr />
        <FormContext.Provider value="Set a new password">
          <h2 className="app_step_subhead">Password</h2>
          <p>
            To update your password you will need to provide your old password
            <br />
            (Passwords must be a minimum of six characters and include at least one letter and one number)
          </p>
          <Messages
            ref={el => {
              updatePasswordMessages.current = el;
            }}
          />
          <Formik onSubmit={updatePassword} validationSchema={passwordValidationSchema} initialValues={updatePasswordInitialValues}>
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-input small-12 medium-6 columns">
                    <label htmlFor="email" className="full_width b_spaced">
                      <span className="label">
                        Old Password <span className="light">*</span>
                      </span>
                      <TextInput type="password" name="old_password" aria-required="true" />
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="form-input small-12 medium-6 columns">
                    <label htmlFor="email" className="full_width b_spaced">
                      <span className="label">
                        New Password <span className="light">*</span>
                      </span>
                      <TextInput type="password" name="password" aria-required="true" />
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="form-input small-12 medium-6 columns">
                    <label htmlFor="email" className="full_width b_spaced">
                      <span className="label">
                        Confirm New Password <span className="light">*</span>
                      </span>
                      <TextInput type="password" name="password_confirmation" aria-required="true" />
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 medium-12 columns">
                    <LoadingButton containerProps={{}} loading={isSubmitting} type="submit">
                      Update password
                    </LoadingButton>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </FormContext.Provider>
        <hr />
        <h2 className="app_step_subhead">Security Question</h2>
        <p>To update your security question and answer, you will need to provide your current password.</p>
        <Messages ref={securityMessages} />
        {questionsMemo}
      </div>
    </div>
  );
}

Account.defaultProps = {
  profile: {},
};

Account.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string,
    mobile: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        question: PropTypes.string,
      }),
    ),
  }),
  loadProfile: PropTypes.func.isRequired,
};
