import AxiosService from '../api/AxiosService';

const handleAcceptCreditAgreement = async (event, locId, pdfFilepath, config, callback, setErrorMessage) => {
  event.preventDefault();
  try {
    await AxiosService.post(
      '/lines_of_credit/' + locId + '/accept_credit_agreement',
      {
        credit_agreement_pdf_filepath: pdfFilepath,
      },
      config,
    );
    callback();
  } catch (error) {
    setErrorMessage(error.message);
  }
};

export default handleAcceptCreditAgreement;
