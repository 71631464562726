import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { motion } from 'framer-motion';
import { useFlag } from 'react-unleash-flags';

import AgreementDownloadLink from '../AgreementDownloadLink';
import BuyArtworkPopup from '../BuyArtworkPopup';

import AxiosService from '../../../api/AxiosService';
import statusStrings from '../statusStrings';
import VerifiedBuyerLogo from '../../../../site/assets/images/logos/AM-verified-buyer-logo.png';
import FormContext from '../../../helpers/FormContext';
import { increaseCreditLink } from '../../../helpers/links';
import getDashboardStatus from '../../../helpers/getDashboardStatus';

import ReadyToArtMoney from '../../Shared/Status/ReadyToArtMoney';
import OneMoreImportantStep from '../../Shared/Status/OneMoreImportantStep';
import MakeAnOffer from '../../Shared/Status/MakeAnOffer';
import AcceptAgreementState from '../../Shared/Status/AcceptAgreementState';
import OneMomentWhileWeReview from '../../Shared/Status/OneMomentWhileWeReview';
import UnableToOfferYouCredit from '../../Shared/Status/UnableToOfferYouCredit';
import CreditHasExpired from '../../Shared/Status/CreditHasExpired';

const Dashboard = ({
  reviewUponCompletion,
  expired,

  hasMadePurchases,
  lineOfCredit,
  locAlloyApplicationData,
  region,
  changeTab,
  verificationStatus,

  verificationType,
  verificationDate,
  verifiedBuyerStatus,
  approvedForCredit,
  verificationComplete,
  needToAcceptCreditAgreement,
  locCounterOffer,
  referredOrAlloyPending,
  locRejected,
  locExpired,
  postHatchLoc,
  clientEmail,

  loadDashboard,
}) => {
  const [maxAmount, setMaxAmount] = useState(0);
  const [openModal, setOpenModal] = useState('');

  const handleOpenModal = useCallback((modal) => {
    setOpenModal(modal);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal('');
  }, []);

  useEffect(() => {
    const getMax = async () => {
      try {
        const res = await AxiosService.get(`countries/${lineOfCredit.country_code}/purchase_limits`);
        setMaxAmount(res?.data?.maximum_purchase);
      } catch (error) {
        //
      }
    };
    if (lineOfCredit?.country_code) {
      getMax();
    }
  }, [lineOfCredit?.country_code]);

  const firstName = lineOfCredit.first_name;
  const verificationPending = verificationStatus === 'pending';

  const handleIncreaseLimit = useCallback(() => {
    window.location = increaseCreditLink(region);
  }, []);

  const handleCreditApplication = useCallback(() => {
    window.location = `/${region}/credit_application`;
  }, []);

  const verifiedBuyerBadge = useMemo(() => {
    const isUKLoC = ['UK', 'GB'].includes(lineOfCredit?.country_code?.toUpperCase() || ' ') && approvedForCredit;
    if (verifiedBuyerStatus?.vba_status === 'verified' || isUKLoC) {
      return (
        <>
          <div className="credit-panel__vb">
            <img src={VerifiedBuyerLogo} alt="Verified Buyer Logo" />
            <div className="credit-panel__status">
              <p className="credit-panel__label">STATUS</p>
              <span>Verified</span>
            </div>
          </div>
        </>
      );
    }
    return null;
  }, [verifiedBuyerStatus, lineOfCredit]);

  const locId = lineOfCredit?.id;
  const currencySymbol = lineOfCredit?.short_currency_symbol;
  const expiryDate = lineOfCredit?.approval_expiry;

  const approvedCredit = (
    <CurrencyFormat value={Math.round(approvedForCredit && lineOfCredit.credit_limit)} displayType="text" thousandSeparator prefix={currencySymbol} />
  );

  const availableCredit = (
    <CurrencyFormat value={Math.round(approvedForCredit && lineOfCredit.available_credit)} displayType="text" thousandSeparator prefix={currencySymbol} />
  );

  // state 1
  // needToAcceptCreditAgreement = true;
  // locCounterOffer = true;

  // state 2
  // needToAcceptCreditAgreement = true;
  // locCounterOffer = false;

  // state 3
  // needToAcceptCreditAgreement = false;
  // approvedForCredit = true;
  // verificationComplete = true;

  // state 4
  // needToAcceptCreditAgreement = false;
  // approvedForCredit = true;
  // verificationComplete = false;

  // state 5
  // needToAcceptCreditAgreement = false;
  // approvedForCredit = false;
  // referredOrAlloyPending = true;

  // state 6
  // needToAcceptCreditAgreement = false;
  // approvedForCredit = false;
  // referredOrAlloyPending = false;
  // locRejected = true;

  // state 7
  // needToAcceptCreditAgreement = false;
  // approvedForCredit = false;
  // referredOrAlloyPending = false;
  // locRejected = false;
  // locExpired = true;

  // state 8
  // needToAcceptCreditAgreement = false;
  // approvedForCredit = false;
  // referredOrAlloyPending = false;
  // locRejected = false;
  // locExpired = false;

  const creditApplicationWorkflowChanges_2023_2024 = useFlag('credit_application_workflow_changes_2023_2024')?.enabled;
  const billingcloudActive = useFlag('billingcloud_active')?.enabled;
  const showBuyButtonOptions = billingcloudActive ? ['au', 'us'].includes(lineOfCredit.country_code) : ['au'].includes(lineOfCredit.country_code);

  const countryCode = lineOfCredit?.country_code;

  const dashboardStatus = getDashboardStatus({
    approvedForCredit,
    verificationPending,
    verificationComplete,
    needToAcceptCreditAgreement,
    locCounterOffer,
    referredOrAlloyPending,
    locRejected,
    locExpired,
    postHatchLoc,
    countryCode,
  });

  const {
    showReadyToArtMoney,
    showOneMoreImportantStep,
    showMakeAnOffer,
    showAcceptAgreementState,
    showOneMomentWhileWeReview,
    showUnableToOfferYouCredit,
    showCreditHasExpired,
    showBuyButton,
    showAvailableCredit,
    showApplyForCredit,
    showNeedToUpdate,
    showAgreementDownloadLink,
  } = dashboardStatus;

  if (creditApplicationWorkflowChanges_2023_2024 && !dashboardStatus) {
    handleCreditApplication();
  }

  return (
    <motion.div className="section client-dashboard-inner__wrap" animate={{ opacity: [0, 1] }} transition={{ duration: 0.5, ease: 'easeOut', delay: 0 }}>
      {!creditApplicationWorkflowChanges_2023_2024 && (
        <>
          <div className="status">
            {approvedCredit &&
              verificationPending &&
              !reviewUponCompletion &&
              !referredOrAlloyPending &&
              !expired &&
              statusStrings(
                'approved_verification_pending_first_time_buyer',
                approvedCredit,
                region,
                firstName,
                changeTab,
                verificationComplete,
                verificationType,
                verificationDate,
              ).message}
            {approvedForCredit &&
              verificationComplete &&
              !hasMadePurchases &&
              !reviewUponCompletion &&
              !referredOrAlloyPending &&
              !expired &&
              statusStrings('approved_verified_first_time_buyer', approvedCredit, region, firstName, changeTab, verificationComplete).message}
            {approvedForCredit &&
              !verificationPending &&
              !verificationComplete &&
              !hasMadePurchases &&
              !reviewUponCompletion &&
              !referredOrAlloyPending &&
              !expired &&
              statusStrings('approved_unverified_first_time_buyer', approvedCredit, region, firstName, changeTab, verificationComplete).message}
            {hasMadePurchases &&
              !reviewUponCompletion &&
              !verificationPending &&
              !referredOrAlloyPending &&
              !expired &&
              statusStrings('repeat_customer', approvedCredit, region, firstName, changeTab, verificationComplete).message}
            {referredOrAlloyPending &&
              !reviewUponCompletion &&
              statusStrings('referredOrAlloyPending', approvedCredit, region, firstName, changeTab, verificationComplete, verificationType).message}
            {expired && !reviewUponCompletion && statusStrings('expired', approvedCredit, region, firstName, changeTab, verificationComplete).message}
            {reviewUponCompletion && statusStrings('review_upon_completion', approvedCredit, region, firstName, changeTab, verificationComplete).message}
            {approvedForCredit && !reviewUponCompletion && (verificationPending || verificationComplete) && (
              <>
                <p>Art Money is not currently financing new sales.</p>
                <p>After almost 10 years of successful growth, we’ve made the difficult decision to pause credit for new purchases whilst we recapitalise.</p>
                <p>Should you have outstanding balances due to Art Money these will continue to be collected over time as per your contracted payment schedule.</p>
                <p>Thank you for your support and we apologise for the inconvenience.</p>
                <p>A more detailed note from our founder is <a href="https://paul-80301.medium.com/the-hardest-thing-359c1d91d6b7">here</a>.</p>
                {/* {lineOfCredit.country_code !== 'au' ? (
                  <a href={`/${region}/loans/create`} className="btn--pri dark text--center">
                    <span className="p-button-text">Buy Artwork</span>
                  </a>
                ) : (
                  <>
                    <button onClick={() => handleOpenModal('buyArtworkPopup')} type="button" className="btn--pri dark text--center">
                      <span className="p-button-text">Buy Artwork</span>
                    </button>
                    <FormContext.Provider value="Choose Interest Free or Buyer's Premium">
                      {openModal === 'buyArtworkPopup' && <BuyArtworkPopup handleCloseModal={handleCloseModal} />}
                    </FormContext.Provider>
                  </>
                )} */}
              </>
            )}
            <p>
              {(approvedForCredit || referredOrAlloyPending) && !verificationComplete && !reviewUponCompletion && !verificationPending && (
                <button onClick={() => changeTab('profile')} type="button" className="btn--pri dark text--center">
                  Verify your ID
                </button>
              )}
              {expired && !reviewUponCompletion && !verificationPending && (
                <p>
                  <a href={increaseCreditLink(region)} className="btn--pri dark text--center">
                    Apply for credit
                  </a>
                </p>
              )}
            </p>
          </div>
          {!reviewUponCompletion && (
            <div className="credit-panel">
              <div className="credit-panel__wrap">
                <div className="credit-panel__row">
                  <div className="credit-panel__block">
                    <div className="credit-panel__label">AVAILABLE CREDIT</div>
                    <div className="credit-panel__value">
                      <span className="credit-panel__currency">{approvedForCredit && lineOfCredit.currency_code}</span>
                      {availableCredit}
                    </div>
                  </div>
                  <div className="credit-panel__block">
                    <div className="credit-panel__label">APPROVED CREDIT</div>
                    <div className="credit-panel__value">
                      <span className="credit-panel__currency">{approvedForCredit && lineOfCredit.currency_code}</span>
                      {approvedCredit}
                    </div>
                  </div>
                </div>

                {approvedForCredit && maxAmount > lineOfCredit?.credit_limit && (
                  <div></div>
                  // Disabled while credit applications are shut down.
                  // <div onClick={handleIncreaseLimit} className="credit-panel__link">
                  //   Request Increase?
                  // </div>
                )}
                {verifiedBuyerBadge}
              </div>
            </div>
          )}
        </>
      )}

      {creditApplicationWorkflowChanges_2023_2024 && (
        <>
          <div className="status">
            {showReadyToArtMoney && <ReadyToArtMoney />}
            {showOneMoreImportantStep && <OneMoreImportantStep approvedCredit={approvedCredit} onClick={() => changeTab('profile')} />}
            {showMakeAnOffer && (
              <MakeAnOffer
                locId={locId}
                clientEmail={clientEmail}
                isLoggedIn={true}
                isVerified={verificationComplete}
                openModal={openModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                locAlloyApplicationData={locAlloyApplicationData}
                currencySymbol={currencySymbol}
                expiryDate={expiryDate}
                hasAcceptedCallback={loadDashboard}
              />
            )}
            {showAcceptAgreementState && (
              <AcceptAgreementState
                locId={locId}
                clientEmail={clientEmail}
                approvedCredit={approvedCredit}
                isLoggedIn={true}
                isVerified={verificationComplete}
                hasAcceptedCallback={loadDashboard}
              />
            )}
            {showOneMomentWhileWeReview && (
              <OneMomentWhileWeReview isLoggedIn={true} isVerified={verificationPending || verificationComplete} changeTab={changeTab} />
            )}
            {showUnableToOfferYouCredit && (
              <UnableToOfferYouCredit
                openModal={openModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                handleCreditApplication={handleCreditApplication}
                locAlloyApplicationData={locAlloyApplicationData}
              />
            )}
            {showCreditHasExpired && <CreditHasExpired region={region} />}

            {showBuyButton && (
              <>
                <p>Art Money is not currently financing new sales.</p>
                <p>After almost 10 years of successful growth, we’ve made the difficult decision to pause credit for new purchases whilst we recapitalise.</p>
                <p>Should you have outstanding balances due to Art Money these will continue to be collected over time as per your contracted payment schedule.</p>
                <p>Thank you for your support and we apologise for the inconvenience.</p>
                <p>A more detailed note from our founder is <a href="https://paul-80301.medium.com/the-hardest-thing-359c1d91d6b7">here</a>.</p>

                {/* {showBuyButtonOptions ? (
                  <>
                    <button onClick={() => handleOpenModal('buyArtworkPopup')} type="button" className="btn--pri dark text--center">
                      <span className="p-button-text">Buy Artwork</span>
                    </button>
                    <FormContext.Provider value="Choose Interest Free or Buyer's Premium">
                      {openModal === 'buyArtworkPopup' && <BuyArtworkPopup handleCloseModal={handleCloseModal} />}
                    </FormContext.Provider>
                  </>
                ) : (
                  <a href={`/${region}/loans/create`} className="btn--pri dark text--center">
                    <span className="p-button-text">Buy Artwork</span>
                  </a>
                )} */}
              </>
            )}

            {showApplyForCredit && (
              <p>
                <a href={increaseCreditLink(region)} className="btn--pri dark text--center">
                  Apply for credit
                </a>
              </p>
            )}

            {
              // if client was approved for credit pre-Hatch, they will need to re-apply
              showNeedToUpdate && (
                <>
                  <p>
                    <strong>We need to update your credit facility.</strong>
                  </p>
                  <p>Due to regulatory changes, we require you to re-apply for Art Money.</p>
                  <p>This is a one time only requirement for US clients, we are sorry for the inconvenience.</p>
                  <p>Applications are a soft check and will not impact your credit score.</p>
                  <p>
                    <a href={increaseCreditLink(region, true)} className="btn--pri dark text--center">
                      Update credit status
                    </a>
                  </p>
                </>
              )
            }
          </div>

          {showAvailableCredit && (
            <div className="credit-panel">
              <div className="credit-panel__wrap">
                <div className="credit-panel__row">
                  <div className="credit-panel__block">
                    <div className="credit-panel__label">AVAILABLE CREDIT</div>
                    <div className="credit-panel__value">
                      <span className="credit-panel__currency">{approvedForCredit && lineOfCredit.currency_code}</span>
                      {availableCredit}
                    </div>
                  </div>
                  <div className="credit-panel__block">
                    <div className="credit-panel__label">APPROVED CREDIT</div>
                    <div className="credit-panel__value">
                      <span className="credit-panel__currency">{approvedForCredit && lineOfCredit.currency_code}</span>
                      {approvedCredit}
                    </div>
                  </div>
                </div>
                {approvedForCredit && maxAmount > lineOfCredit?.credit_limit && (
                  <div></div>
                  // Disabled while credit applications are shut down.
                  // <div onClick={handleIncreaseLimit} className="credit-panel__link">
                  //   Request increase?
                  // </div>
                )}
                {verifiedBuyerBadge}
                {showAgreementDownloadLink && <AgreementDownloadLink locId={locId} />}
              </div>
            </div>
          )}
        </>
      )}
    </motion.div>
  );
};

Dashboard.defaultProps = {
  verificationType: null,
  verificationDate: null,
  verifiedBuyerStatus: null,
};

Dashboard.propTypes = {
  reviewUponCompletion: PropTypes.bool.isRequired,
  expired: PropTypes.bool.isRequired,

  hasMadePurchases: PropTypes.bool.isRequired,

  lineOfCredit: PropTypes.shape({
    country_code: PropTypes.string,
    currency_code: PropTypes.string,
    first_name: PropTypes.string,
    credit_limit: PropTypes.number,
    short_currency_symbol: PropTypes.string,
    available_credit: PropTypes.number,
  }).isRequired,
  region: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  verificationStatus: PropTypes.string.isRequired,
  verificationType: PropTypes.string,
  verificationDate: PropTypes.string,
  verifiedBuyerStatus: PropTypes.shape({
    vba_status: PropTypes.string,
  }),
  approvedForCredit: PropTypes.bool.isRequired,
  verificationComplete: PropTypes.bool.isRequired,
  needToAcceptCreditAgreement: PropTypes.bool.isRequired,
  locCounterOffer: PropTypes.bool.isRequired,
  referredOrAlloyPending: PropTypes.bool.isRequired,
  locRejected: PropTypes.bool.isRequired,
  locExpired: PropTypes.bool.isRequired,
};

export default Dashboard;
