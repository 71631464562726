import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useGoogleAnalyticsEventTracking from '../../../hooks/useGoogleAnalyticsEventTracking';

const Card = ({ name, description, learnMoreUrl, buyUrl, backgroundColor }) => {
  const { sendCustomFormStepCompleteEvent } = useGoogleAnalyticsEventTracking();
  const handleBuyClick = useCallback(() => {
    sendCustomFormStepCompleteEvent({ stepName: name, stepNumber: 1 });
  }, []);
  return (
    <>
      <div className="artwork-popup-card">
        <div className="artwork-popup-card__outer" style={{ backgroundColor }}>
          <div className="artwork-popup-card__inner">
            <h3 className="artwork-popup-card__heading">Art Money {name}</h3>
            <p className="artwork-popup-card__description">{description}</p>
            <a className="artwork-popup-card__learn" href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          </div>
        </div>
      </div>
      <div>
        <a onClick={handleBuyClick} className="artwork-popup-card__buy" href={buyUrl}>
          Buy with {name}
        </a>
      </div>
    </>
  );
};

Card.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  learnMoreUrl: PropTypes.string.isRequired,
  buyUrl: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default Card;
