/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

/*
  This is a custom dropzone component that is used in the formik form in the
  BuyersPremium component. It essentially recreates the markup from the default
  Dropzone component. This is done because Dropzone does not work with formik
  due to it making the input component disappear when a file is selected.
*/

import React, { useRef } from 'react';
import { useField } from 'formik';

const CustomDropzone = ({ name, text }) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const inputRef = useRef(null);
  function resetInput() {
    inputRef.current.value = '';
    setValue('');
  }

  return (
    <div className="dzu-dropzone">
      {!!field.value && (
        <div className="dzu-previewContainer">
          <span className="dzu-previewFileName">{field.value.name}</span>
          <div className="dzu-previewStatusContainer">
            <span
              onClick={resetInput}
              className="dzu-previewButton"
              style={{
                backgroundImage:
                  'url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUuMCwgMC4wKSIgZmlsbD0iIzMzMzMzMyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC4wLCAwLjApIj48cG9seWdvbiBwb2ludHM9IjcuNzE5IDQuOTY0IDEyLjY5MiAwLjAxNyAxNC4zODkgMS43MTUgOS40MTIgNi42NjYgMTQuMzU0IDExLjYzNCAxMi42NTcgMTMuMzMxIDYuMDE3IDYuNjU3IDcuNzE1IDQuOTYwIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSI3LjYxMiA0Ljk2NCA3LjYxNiA0Ljk2MCA5LjMxMyA2LjY1NyAyLjY3NCAxMy4zMzEgMC45NzcgMTEuNjM0IDUuOTE5IDYuNjY2IDAuOTQyIDEuNzE1IDIuNjM5IDAuMDE3Ij48L3BvbHlnb24+PC9nPjwvZz48L3N2Zz4K")',
              }}
            />
          </div>
        </div>
      )}
      <label className="dzu-inputLabel">
        {!field.value && text}
        <input
          ref={inputRef}
          name={name}
          className="dzu-input"
          type="file"
          accept="*"
          onChange={event => {
            setValue(event.currentTarget.files?.[0] ?? '');
          }}
        />
      </label>
    </div>
  );
};

export default CustomDropzone;
