import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal } from '@material-ui/core';
import CloseIcon from '../../Icons/CloseIcon';
import SharedCopyForReasoningModals from './SharedCopyForReasoningModals';

function CounterOfferReasoning({ isOpen, handleCloseModal, approvedCredit, locAlloyApplicationData, expiryDate }) {
  const outcomeReasons = locAlloyApplicationData.outcome_reasons ? locAlloyApplicationData.outcome_reasons : [];
  const formattedExpirydate = moment(expiryDate).format('d MMMM yyyy');

  return (
    <Modal className="status-modal" open={isOpen} onClose={handleCloseModal} aria-labelledby="modal-title">
      <div className="row">
        <div className="status-modal__bg">
          <button type="button" className="status-modal__close float-right" onClick={handleCloseModal} aria-label="close popup">
            <CloseIcon />
          </button>
          <h2 id="modal-title">Credit offer</h2>
          <p>Thank you for your recent application for a line of credit through Art Money from our lending partner: Hatch Bank.</p>
          <p>
            After careful consideration by our Lending Partner, we are unable to approve your application for the amount that you've requested. The principal
            reason{outcomeReasons.length > 1 && 's'} for our decision {outcomeReasons.length > 1 ? 'are' : 'is'} indicated below:
          </p>
          <ul>
            {outcomeReasons.map((reason, index) => (
              <li key={index}>{reason.name}</li>
            ))}
          </ul>
          <p>
            We can, however, offer you credit for the following amount: <b>{approvedCredit}</b>.
          </p>
          <p>If this offer is acceptable to you, please log on to your Art Money Account and accept the agreement by {formattedExpirydate}.</p>
          <SharedCopyForReasoningModals locAlloyApplicationData={locAlloyApplicationData} />
        </div>
      </div>
    </Modal>
  );
}

CounterOfferReasoning.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  approvedCredit: PropTypes.object.isRequired,
  locAlloyApplicationData: PropTypes.object.isRequired,
  expiryDate: PropTypes.string.isRequired,
};

export default CounterOfferReasoning;
